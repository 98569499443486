import { useState } from "react";
import useScrollTo from "../../../hooks/useScrollTo";
import TextInput from "../TextInput";
import YesNoInput from "../YesNoInput";
import TextArea from "../TextArea";

const DiscussionSix = ({ state, dispatch }) => {
  const [sixthAgenda, setSixthAgenda] = useState(state.sixthAgenda || "");
  const [sixthAgendaIssues, setSixthAgendaIssues] = useState(
    state.sixthAgendaIssues || ""
  );
  const [sixthAgendaOptions, setSixthAgendaOptions] = useState(
    state.sixthAgendaOptions || ""
  );
  const [sixthAgendaAgreements, setSixthAgendaAgreements] = useState(
    state.sixthAgendaAgreements
  );
  const [sixthAgendaAgreementsPoints, setSixthAgendaAgreementsPoints] =
    useState(state.sixthAgendaAgreementsPoints || "");
  const [sixthAgendaActionsPoints, setSixthAgendaActionsPoints] = useState(
    state.sixthAgendaActionsPoints || ""
  );
  const [seventhAgendaCheck, setSeventhAgendaCheck] = useState(
    state.seventhAgendaCheck
  );

  useScrollTo(0, 0, "smooth");

  const onSixthAgendaChange = ({ target }) => {
    setSixthAgenda(target.value);
    dispatch({ type: "SIXTH_AGENDA", payload: target.value });
  };
  const onSixthAgendaIssuesChange = ({ target }) => {
    setSixthAgendaIssues(target.value);
    dispatch({ type: "SIXTH_AGENDA_ISSUES", payload: target.value });
  };
  const onSixthAgendaOptionsChange = ({ target }) => {
    setSixthAgendaOptions(target.value);
    dispatch({ type: "SIXTH_AGENDA_OPTIONS", payload: target.value });
  };
  const onSixthAgendaAgreementsChange = ({ target }) => {
    setSixthAgendaAgreements(target.value);
    dispatch({ type: "SIXTH_AGENDA_AGREEMENTS", payload: target.value });
  };
  const onSixthAgendaAgreementsPointsChange = ({ target }) => {
    setSixthAgendaAgreementsPoints(target.value);
    dispatch({
      type: "SIXTH_AGENDA_AGREEMENTS_POINTS",
      payload: target.value,
    });
  };
  const onSixthAgendaActionsPointsChange = ({ target }) => {
    setSixthAgendaActionsPoints(target.value);
    dispatch({ type: "SIXTH_AGENDA_ACTIONS_POINTS", payload: target.value });
  };
  const onSeventhAgendaCheck = ({ target }) => {
    setSeventhAgendaCheck(target.value);
    dispatch({ type: "SEVENTH_AGENDA_CHECK", payload: target.value });
  };
  return (
    <>
      <h2>Discussion - Point 6</h2>
      <TextInput
        label="sixth agenda point discussed."
        id="sixth_agenda"
        onChangeText={onSixthAgendaChange}
        value={sixthAgenda}
        required={true}
      />
      <TextArea
        rows={8}
        label="Issues identified"
        id="issues-identified"
        onTextChange={onSixthAgendaIssuesChange}
        value={sixthAgendaIssues}
        required={true}
        guide="Please add details and context about the issues discussed."
      />
      <TextArea
        rows={8}
        label="Options discussed"
        id="options-discussed"
        onTextChange={onSixthAgendaOptionsChange}
        value={sixthAgendaOptions}
        required={true}
        guide="List the proposals put forward by the participants."
      />
      <YesNoInput
        label="Any agreements reached?"
        name="agreements-reached"
        checkedTerm={sixthAgendaAgreements}
        onOptionChange={onSixthAgendaAgreementsChange}
        required={true}
        noId="no-agreements-reached"
        noLabel="No"
        yesId="yes-agreements-reached"
        yesLabel="Yes"
      />
      {sixthAgendaAgreements === "Yes" && (
        <TextArea
          rows={8}
          label="Please bullet point any agreements reached"
          name="agreement-points"
          id="agreement-points"
          value={sixthAgendaAgreementsPoints}
          onTextChange={onSixthAgendaAgreementsPointsChange}
          guide="Ensure there is enough detail to stablish a clear agreement."
          required={true}
        />
      )}
      <TextInput
        label="Action points for the clients"
        name="action-points"
        id="action-points"
        guide="Please specify any practical activities/actions that need to take place to allow the agreements to be reached."
        value={sixthAgendaActionsPoints}
        onChangeText={onSixthAgendaActionsPointsChange}
        required={true}
      />
      <YesNoInput
        label="Is there a seventh agenda point?"
        checkedTerm={seventhAgendaCheck}
        onOptionChange={onSeventhAgendaCheck}
        name="seventh-agenda"
        required={true}
        noId="no-seventh-agenda"
        noLabel="No"
        yesId="yes-seventh-agenda"
        yesLabel="Yes"
      />
    </>
  );
};

export default DiscussionSix;
