import { useState } from "react";
import useScrollTo from "../hooks/useScrollTo";
import SelectInput from "../FormComponents/Components/SelectInput";
import TextInput from "../FormComponents/Components/TextInput";
import YesNoInput from "../FormComponents/Components/YesNoInput";
import TextArea from "../FormComponents/Components/TextArea";
import { useEffect } from "react";

const furtherMediationOptions = ["Yes", "No", "Considering"];
const finishedReasons = [
  "A - All/Some matters agreed",
  "B - Mediation broken down/no longer suitable",
  "C - Successful - Parenting plan to be written",
  "P - Successful - MOU to be written",
  "S - Successful - Most matters agreed and/or PP and/or MOU to be written",
];

const NextSteps = ({ state, dispatch }) => {
  const [isFurtherSession, setIsFurtherSession] = useState(
    state.isFurtherSession || "select"
  );
  const [nextSessionIssues, setNextSessionIssues] = useState(
    state.nextSessionIssues || ""
  );
  const [bothClientsAgreed, setBothClientsAgreed] = useState(
    state.bothClientsAgreed
  );
  const [returningToMediationDate, setReturningToMediationDate] = useState(
    state.returningToMediationDate || ""
  );
  const [appointmentTime, setAppointmentTime] = useState(
    state.appointmentTime || ""
  );
  const [mediationFinishedReason, setMediationFinishedReason] = useState(
    state.mediationFinishedReason || "select"
  );
  const [wantToUpload, setWantToUpload] = useState(state.wantToUpload);
  const [evidence, setEvidence] = useState(state.evidence || null);
  const [bothClientEmail, setBothClientEmail] = useState(state.bothClientEmail);
  const [clientOneEmailAddress, setClientOneEmailAddress] = useState(
    state.clientOneEmailAddress || ""
  );
  const [clientTwoEmailAddress, setClientTwoEmailAddress] = useState(
    state.clientTwoEmailAddress || ""
  );
  const [isMediatorEmail, setIsMediatorEmail] = useState(state.isMediatorEmail);
  const [mediatorEmailAddress, setMediatorEmailAddress] = useState(
    state.mediatorEmailAddress || ""
  );
  const [mediatorComments, setMediatorComments] = useState(
    state.mediatorComments || ""
  );

  useScrollTo(0, 0, "smooth");

  const onIsFurtherSessionChange = ({ target }) => {
    setIsFurtherSession(target.value);
    dispatch({ type: "FURTHER_SESSION", payload: target.value });
  };
  const onNextSessionChange = ({ target }) => {
    setNextSessionIssues(target.value);
    dispatch({ type: "FURTHER_ISSUES", payload: target.value });
  };
  const onBothClientAgreedChange = ({ target }) => {
    setBothClientsAgreed(target.value);
    dispatch({ type: "BOTH_CLIENT", payload: target.value });
  };
  const onReturningToMediationChange = ({ target }) => {
    setReturningToMediationDate(target.value);
    dispatch({ type: "RETURNING_TO_MEDIATION", payload: target.value });
  };
  const onAppointmentTimeChange = ({ target }) => {
    setAppointmentTime(target.value);
    dispatch({ type: "APPOINTMENT_TIME", payload: target.value });
  };
  const onMediationFinishedReason = ({ target }) => {
    setMediationFinishedReason(target.value);
    dispatch({ type: "MEDIATION_FINISHED_REASON", payload: target.value });
  };
  const onWantToUploadChange = ({ target }) => {
    setWantToUpload(target.value);
    dispatch({ type: "WANT_TO_UPLOAD", payload: target.value });
  };
  const onUploadedDocument = (event) => {
    setEvidence(event.target.files[0]);
  };
  const onBothClientEmailChange = ({ target }) => {
    setBothClientEmail(target.value);
    dispatch({ type: "BOTH_CLIENT_EMAIL", payload: target.value });
  };
  const onClientOneEmailAddressChange = ({ target }) => {
    setClientOneEmailAddress(target.value);
    dispatch({ type: "CLIENT_ONE_EMAIL", payload: target.value });
  };
  const onClientTwoEmailAddressChange = ({ target }) => {
    setClientTwoEmailAddress(target.value);
    dispatch({ type: "CLIENT_TWO_EMAIL", payload: target.value });
  };
  const onMediatorEmailChange = ({ target }) => {
    setIsMediatorEmail(target.value);
    dispatch({ type: "MEDIATOR_EMAIL", payload: target.value });
  };
  const onMediatorEmailAddressChange = ({ target }) => {
    setMediatorEmailAddress(target.value);
    dispatch({ type: "MEDIATOR_EMAIL_ADDRESS", payload: target.value });
  };
  const onMediatorCommentsChange = ({ target }) => {
    setMediatorComments(target.value);
    dispatch({ type: "MEDIATOR_COMMENT", payload: target.value });
  };

  useEffect(() => {
    dispatch({ type: "EVIDENCE", payload: evidence });
  }, [dispatch, evidence]);

  return (
    <>
      <h2>Next Steps</h2>
      <SelectInput
        label="Has a further mediation session been planned?"
        options={furtherMediationOptions}
        optionValue={isFurtherSession}
        onOptionChange={onIsFurtherSessionChange}
        required={true}
      />
      {(isFurtherSession === "Yes" || isFurtherSession === "Considering") && (
        <TextArea
          label="Issues for discussion in next session?"
          id="nextSessionIssues"
          value={nextSessionIssues}
          onTextChange={onNextSessionChange}
          required={true}
          rows={8}
        />
      )}
      {isFurtherSession === "Yes" && (
        <YesNoInput
          label="Have both clients agreed on a date and time to come back to mediation?"
          required={true}
          checkedTerm={bothClientsAgreed}
          onOptionChange={onBothClientAgreedChange}
          name="bothClientsAgreed"
          noId="no-both-clients-agreed"
          yesId="yes-both-clients-agreed"
          noLabel="No"
          yesLabel="Yes"
        />
      )}
      {bothClientsAgreed === "Yes" && isFurtherSession === "Yes" && (
        <>
          <div className="form-control colum-direction">
            <label htmlFor="date-of-Mediation">
              What is the date they are returning to mediation?{" "}
              <span className="color-red">*</span>
            </label>
            <input
              type="date"
              name="date-of-Mediation"
              id="date-of-Mediation"
              value={returningToMediationDate}
              onChange={onReturningToMediationChange}
              required
            />
          </div>
          <TextInput
            label="What time is their appointment?"
            required={true}
            id="appointmentTime"
            value={appointmentTime}
            onChangeText={onAppointmentTimeChange}
          />
        </>
      )}
      {isFurtherSession === "No" && (
        <SelectInput
          label="What is the reason mediation has been finished?"
          options={finishedReasons}
          optionValue={mediationFinishedReason}
          onOptionChange={onMediationFinishedReason}
          required={true}
        />
      )}
      <YesNoInput
        label="Do you want to upload the C100 and/or Form A?"
        checkedTerm={wantToUpload}
        onOptionChange={onWantToUploadChange}
        required={true}
        name="wantToUpload"
        noId="noWantToUpload"
        yesId="yesWantToUpload"
        noLabel="No"
        yesLabel="Yes"
      />
      <div className="form-control colum-direction">
        <label htmlFor="evidence">Please upload the documents.</label>
        <p className="guide-label">This is not a compulsory field.</p>
        <input
          type="file"
          name="evidence"
          id="evidence"
          onChange={onUploadedDocument}
        />
      </div>
      <YesNoInput
        label="Do both clients want the session record emailing to them?"
        name="both-clients"
        checkedTerm={bothClientEmail}
        onOptionChange={onBothClientEmailChange}
        required={true}
        noId="no-both-client"
        yesId="yes-both-client"
        noLabel="No"
        yesLabel="Yes"
      />
      <div className="form-control colum-direction">
        <label htmlFor="email">
          Input email address of {state.clientOneFullName}?
          <span className="color-red">*</span>
        </label>
        <input
          type="email"
          name="client-one-email"
          id="client-one-email"
          required
          value={clientOneEmailAddress}
          onChange={onClientOneEmailAddressChange}
        />
      </div>
      {bothClientEmail === "Yes" && (
        <div className="form-control colum-direction">
          <label htmlFor="email">
            Input email address of {state.clientTwoFullName}?
            <span className="color-red">*</span>
          </label>
          <input
            type="email"
            name="client-two-email"
            id="client-two-email"
            required
            value={clientTwoEmailAddress}
            onChange={onClientTwoEmailAddressChange}
          />
        </div>
      )}
      <YesNoInput
        label="Do you as the mediator want a copy of this record sending to you?"
        name="is-mediator-email"
        checkedTerm={isMediatorEmail}
        onOptionChange={onMediatorEmailChange}
        required={true}
        noId="no-mediator-email"
        yesId="yes-mediator-email"
        noLabel="No"
        yesLabel="Yes"
      />
      {isMediatorEmail === "Yes" && (
        <div className="form-control colum-direction">
          <label htmlFor="email">
            Input email address of mediator<span className="color-red">*</span>
          </label>
          <input
            type="email"
            name="mediator-email"
            id="mediator-email"
            required
            value={mediatorEmailAddress}
            onChange={onMediatorEmailAddressChange}
          />
        </div>
      )}
      <TextArea
        label="Mediator's comments (these are not released to the clients and won't appear in the mediation session record)"
        guide="This field is NOT compulsory."
        id="mediator-comments"
        rows={5}
        value={mediatorComments}
        onTextChange={onMediatorCommentsChange}
      />
    </>
  );
};

export default NextSteps;
