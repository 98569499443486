import { patchRecordSession } from "../Logic/patchRecordSession";
import { client } from "../axios/axios";

const uploadFileToDrive = (id, formStateMediationSession) => {
  const formData = new FormData();
  formData.append("files", formStateMediationSession.evidence);
  formData.append("files", formStateMediationSession.evidences);

  client
    .post(`/uploadFiles/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .catch((err) => {
      console.log({ err });
    });
};

export const onNextChange = (
  e,
  next,
  currentStepIndex,
  formStateMediationSession,
  goTo,
  steps,
  setIsSubmitted,
  id
) => {
  e.preventDefault();
  next();

  if (
    currentStepIndex === 3 &&
    formStateMediationSession.secondAgendaCheck === "No"
  ) {
    goTo(11);
  }
  if (
    currentStepIndex === 4 &&
    formStateMediationSession.thirdAgendaCheck === "No"
  ) {
    goTo(11);
  }
  if (
    currentStepIndex === 5 &&
    formStateMediationSession.fourthAgendaCheck === "No"
  ) {
    goTo(11);
  }
  if (
    currentStepIndex === 6 &&
    formStateMediationSession.fifthAgendaCheck === "No"
  ) {
    goTo(11);
  }
  if (
    currentStepIndex === 7 &&
    formStateMediationSession.sixthAgendaCheck === "No"
  ) {
    goTo(11);
  }
  if (
    currentStepIndex === 8 &&
    formStateMediationSession.seventhAgendaCheck === "No"
  ) {
    goTo(11);
  }
  if (
    currentStepIndex === 9 &&
    formStateMediationSession.eighthAgendaCheck === "No"
  ) {
    goTo(11);
  }
  if (currentStepIndex + 1 === steps.length) {
    patchRecordSession(formStateMediationSession, id, setIsSubmitted);
    uploadFileToDrive(id, formStateMediationSession);
  }
};
export const onBackChange = (
  e,
  back,
  currentStepIndex,
  formStateMediationSession,
  goTo
) => {
  e.preventDefault();
  back();
  if (
    currentStepIndex === 11 &&
    formStateMediationSession.secondAgendaCheck === "No"
  ) {
    goTo(3);
  }
  if (
    currentStepIndex === 11 &&
    formStateMediationSession.thirdAgendaCheck === "No"
  ) {
    goTo(4);
  }
  if (
    currentStepIndex === 11 &&
    formStateMediationSession.fourthAgendaCheck === "No"
  ) {
    goTo(5);
  }
  if (
    currentStepIndex === 11 &&
    formStateMediationSession.fifthAgendaCheck === "No"
  ) {
    goTo(6);
  }
  if (
    currentStepIndex === 11 &&
    formStateMediationSession.sixthAgendaCheck === "No"
  ) {
    goTo(7);
  }
  if (
    currentStepIndex === 11 &&
    formStateMediationSession.seventhAgendaCheck === "No"
  ) {
    goTo(8);
  }
  if (
    currentStepIndex === 11 &&
    formStateMediationSession.eighthAgendaCheck === "No"
  ) {
    goTo(9);
  }
};
