export const reducer = (state, { type, payload }) => {
  // console.log({ state, type, payload });

  switch (type) {
    case "CLIENT_ONE_FULL_NAME":
      return { ...state, clientOneFullName: payload };
    case "CLIENT_TWO_FULL_NAME":
      return { ...state, clientTwoFullName: payload };
    case "MEDIATOR_NAME":
      return { ...state, mediatorName: payload };
    case "SESSION_DATE":
      return { ...state, sessionDate: payload };
    case "CASE_TYPE":
      return { ...state, caseType: payload };
    case "SPECIFY_LOCATION":
      return { ...state, specifyLocation: payload };
    case "MEDIATION_SESSION_LOCATION":
      return { ...state, mediationSessionLocation: payload };
    case "MEDIATION_SESSION_NUMBER":
      return { ...state, mediationSessionNumber: payload };
    case "SESSION_LENGTH":
      return { ...state, sessionLength: payload };
    case "HAVE_CHILDREN":
      return { ...state, haveChildren: payload };
    case "FIRST_CHILD_FULL_NAME":
      return { ...state, firstChildFullName: payload };
    case "FIRST_CHILD_DATE_OF_BIRTH":
      return { ...state, firstChildDateOfBirth: payload };
    case "FIRST_CHILD_BOTH_PARENTAL_RESPONSIBILITY":
      return { ...state, firstChildBothParentalResponsibility: payload };
    case "FIRST_CHILD_PARENTAL_RESPONSIBILITY":
      return { ...state, firstChildParentalResponsibility: payload };
    case "SECOND_CHILD_CHECK":
      return { ...state, secondChildCheck: payload };
    case "SECOND_CHILD_FULL_NAME":
      return { ...state, SecondChildFullName: payload };
    case "SECOND_CHILD_DATE_OF_BIRTH":
      return { ...state, SecondChildDateOfBirth: payload };
    case "SECOND_CHILD_PARENTAL_RESPONSIBILITY_BOTH":
      return { ...state, secondChildBothParentalResponsibility: payload };
    case "SECOND_CHILD_PARENTAL_RESPONSIBILITY":
      return { ...state, SecondChildParentalResponsibility: payload };
    case "THIRD_CHILD_CHECK":
      return { ...state, thirdChildCheck: payload };
    case "THIRD_CHILD_FULL_NAME":
      return { ...state, thirdChildFullName: payload };
    case "THIRD_CHILD_DATE_OF_BIRTH":
      return { ...state, thirdChildDateOfBirth: payload };
    case "THIRD_CHILD_PARENTAL_RESPONSIBILITY_BOTH":
      return { ...state, thirdChildBothParentalResponsibility: payload };
    case "THIRD_CHILD_PARENTAL_RESPONSIBILITY":
      return { ...state, thirdChildParentalResponsibility: payload };
    case "FOURTH_CHILD_CHECK":
      return { ...state, fourthChildCheck: payload };
    case "FOURTH_CHILD_FULL_NAME":
      return { ...state, fourthChildFullName: payload };
    case "FOURTH_CHILD_DATE_OF_BIRTH":
      return { ...state, fourthChildDateOfBirth: payload };
    case "FOURTH_CHILD_PARENTAL_RESPONSIBILITY_BOTH":
      return { ...state, fourthChildBothParentalResponsibility: payload };
    case "FOURTH_CHILD_PARENTAL_RESPONSIBILITY":
      return { ...state, fourthChildParentalResponsibility: payload };
    case "CHILD_ISSUE":
      return { ...state, childIssue: payload };
    case "FINANCE":
      return { ...state, finance: payload };
    case "ALL_ISSUE":
      return { ...state, allIssues: payload };
    case "MEDIATION_TYPE":
      return { ...state, mediationTypes: payload };
    case "AGREE_TO_MEDIATE":
      return { ...state, agreeToMediate: payload };
    case "AGENDA_POINTS_LIST":
      return { ...state, agendaPointsList: payload };
    case "FIRST_AGENDA":
      return { ...state, firstAgenda: payload };
    case "FIRST_AGENDA_ISSUES":
      return { ...state, firstAgendaIssues: payload };
    case "FIRST_AGENDA_OPTIONS":
      return { ...state, firstAgendaOptions: payload };
    case "FIRST_AGENDA_AGREEMENTS":
      return { ...state, firstAgendaAgreements: payload };
    case "FIRST_AGENDA_AGREEMENTS_POINTS":
      return { ...state, firstAgendaAgreementsPoints: payload };
    case "FIRST_AGENDA_ACTIONS_POINTS":
      return { ...state, firstAgendaActionsPoints: payload };
    case "SECOND_AGENDA_CHECK":
      return { ...state, secondAgendaCheck: payload };
    case "SECOND_AGENDA":
      return { ...state, secondAgenda: payload };
    case "SECOND_AGENDA_ISSUES":
      return { ...state, secondAgendaIssues: payload };
    case "SECOND_AGENDA_OPTIONS":
      return { ...state, secondAgendaOptions: payload };
    case "SECOND_AGENDA_AGREEMENTS":
      return { ...state, secondAgendaAgreements: payload };
    case "SECOND_AGENDA_AGREEMENTS_POINTS":
      return { ...state, secondAgendaAgreementsPoints: payload };
    case "SECOND_AGENDA_ACTIONS_POINTS":
      return { ...state, secondAgendaActionsPoints: payload };
    case "THIRD_AGENDA_CHECK":
      return { ...state, thirdAgendaCheck: payload };
    case "THIRD_AGENDA":
      return { ...state, thirdAgenda: payload };
    case "THIRD_AGENDA_ISSUES":
      return { ...state, thirdAgendaIssues: payload };
    case "THIRD_AGENDA_OPTIONS":
      return { ...state, thirdAgendaOptions: payload };
    case "THIRD_AGENDA_AGREEMENTS":
      return { ...state, thirdAgendaAgreements: payload };
    case "THIRD_AGENDA_AGREEMENTS_POINTS":
      return { ...state, thirdAgendaAgreementsPoints: payload };
    case "THIRD_AGENDA_ACTIONS_POINTS":
      return { ...state, thirdAgendaActionsPoints: payload };
    case "FOURTH_AGENDA_CHECK":
      return { ...state, fourthAgendaCheck: payload };
    case "FOURTH_AGENDA":
      return { ...state, fourthAgenda: payload };
    case "FOURTH_AGENDA_ISSUES":
      return { ...state, fourthAgendaIssues: payload };
    case "FOURTH_AGENDA_OPTIONS":
      return { ...state, fourthAgendaOptions: payload };
    case "FOURTH_AGENDA_AGREEMENTS":
      return { ...state, fourthAgendaAgreements: payload };
    case "FOURTH_AGENDA_AGREEMENTS_POINTS":
      return { ...state, fourthAgendaAgreementsPoints: payload };
    case "FOURTH_AGENDA_ACTIONS_POINTS":
      return { ...state, fourthAgendaActionsPoints: payload };
    case "FIFTH_AGENDA_CHECK":
      return { ...state, fifthAgendaCheck: payload };
    case "FIFTH_AGENDA":
      return { ...state, fifthAgenda: payload };
    case "FIFTH_AGENDA_ISSUES":
      return { ...state, fifthAgendaIssues: payload };
    case "FIFTH_AGENDA_OPTIONS":
      return { ...state, fifthAgendaOptions: payload };
    case "FIFTH_AGENDA_AGREEMENTS":
      return { ...state, fifthAgendaAgreements: payload };
    case "FIFTH_AGENDA_AGREEMENTS_POINTS":
      return { ...state, fifthAgendaAgreementsPoints: payload };
    case "FIFTH_AGENDA_ACTIONS_POINTS":
      return { ...state, fifthAgendaActionsPoints: payload };
    case "SIXTH_AGENDA_CHECK":
      return { ...state, sixthAgendaCheck: payload };
    case "SIXTH_AGENDA":
      return { ...state, sixthAgenda: payload };
    case "SIXTH_AGENDA_ISSUES":
      return { ...state, sixthAgendaIssues: payload };
    case "SIXTH_AGENDA_OPTIONS":
      return { ...state, sixthAgendaOptions: payload };
    case "SIXTH_AGENDA_AGREEMENTS":
      return { ...state, sixthAgendaAgreements: payload };
    case "SIXTH_AGENDA_AGREEMENTS_POINTS":
      return { ...state, sixthAgendaAgreementsPoints: payload };
    case "SIXTH_AGENDA_ACTIONS_POINTS":
      return { ...state, sixthAgendaActionsPoints: payload };
    case "SEVENTH_AGENDA_CHECK":
      return { ...state, seventhAgendaCheck: payload };
    case "SEVENTH_AGENDA":
      return { ...state, seventhAgenda: payload };
    case "SEVENTH_AGENDA_ISSUES":
      return { ...state, seventhAgendaIssues: payload };
    case "SEVENTH_AGENDA_OPTIONS":
      return { ...state, seventhAgendaOptions: payload };
    case "SEVENTH_AGENDA_AGREEMENTS":
      return { ...state, seventhAgendaAgreements: payload };
    case "SEVENTH_AGENDA_AGREEMENTS_POINTS":
      return { ...state, seventhAgendaAgreementsPoints: payload };
    case "SEVENTH_AGENDA_ACTIONS_POINTS":
      return { ...state, seventhAgendaActionsPoints: payload };
    case "EIGHTH_AGENDA_CHECK":
      return { ...state, eighthAgendaCheck: payload };
    case "EIGHTH_AGENDA":
      return { ...state, eighthAgenda: payload };
    case "EIGHTH_AGENDA_ISSUES":
      return { ...state, eighthAgendaIssues: payload };
    case "EIGHTH_AGENDA_OPTIONS":
      return { ...state, eighthAgendaOptions: payload };
    case "EIGHTH_AGENDA_AGREEMENTS":
      return { ...state, eighthAgendaAgreements: payload };
    case "EIGHTH_AGENDA_AGREEMENTS_POINTS":
      return { ...state, eighthAgendaAgreementsPoints: payload };
    case "EIGHTH_AGENDA_ACTIONS_POINTS":
      return { ...state, eighthAgendaActionsPoints: payload };
    case "ADDITIONAL_DOCUMENTS":
      return { ...state, additionalDocuments: payload };
    case "FURTHER_SESSION":
      return { ...state, isFurtherSession: payload };
    case "FURTHER_ISSUES":
      return { ...state, nextSessionIssues: payload };
    case "BOTH_CLIENT":
      return { ...state, bothClientsAgreed: payload };
    case "RETURNING_TO_MEDIATION":
      return { ...state, returningToMediationDate: payload };
    case "APPOINTMENT_TIME":
      return { ...state, appointmentTime: payload };
    case "MEDIATION_FINISHED_REASON":
      return { ...state, mediationFinishedReason: payload };
    case "WANT_TO_UPLOAD":
      return { ...state, wantToUpload: payload };
    case "BOTH_CLIENT_EMAIL":
      return { ...state, bothClientEmail: payload };
    case "CLIENT_ONE_EMAIL":
      return { ...state, clientOneEmailAddress: payload };
    case "CLIENT_TWO_EMAIL":
      return { ...state, clientTwoEmailAddress: payload };
    case "MEDIATOR_EMAIL":
      return { ...state, isMediatorEmail: payload };
    case "MEDIATOR_EMAIL_ADDRESS":
      return { ...state, mediatorEmailAddress: payload };
    case "MEDIATOR_COMMENT":
      return { ...state, mediatorComments: payload };
    case "EVIDENCES":
      return { ...state, evidences: payload };
    case "EVIDENCE":
      return { ...state, evidence: payload };
    default:
      return state;
  }
};

export const defaultState = {
  caseType: "select",
  specifyLocation: "select",
  mediationSessionNumber: "select",
  firstChildBothParentalResponsibility: "select",
  secondChildBothParentalResponsibility: "select",
  thirdChildBothParentalResponsibility: "select",
  fourthChildBothParentalResponsibility: "select",
  mediationTypes: "select",
  isFurtherSession: "select",
  mediationFinishedReason: "select",
};
