import React, { useEffect, useReducer, useRef, useState } from "react";

import { defaultState, reducer } from "../Logic/reducer";
import { useMultiStepForm } from "../hooks/useMultiStepForm";

import FormComponent from "./FormComponent";
import logo from "../assets/dms-logotype2.png";
import FirstPage from "../pages/FirstPage";
import KeyFacts from "../pages/KeyFacts";
import RecordOfMattersDiscussed from "../pages/RecordOfMattersDiscussed";

import DiscussionOne from "./Components/Discussion/DiscussionOne";
import DiscussionTwo from "./Components/Discussion/DiscussionTwo";
import DiscussionThree from "./Components/Discussion/DiscussionThree";
import DiscussionFour from "./Components/Discussion/DiscussionFour";
import DiscussionFive from "./Components/Discussion/DiscussionFive";
import DiscussionSix from "./Components/Discussion/DiscussionSix";
import DiscussionSeven from "./Components/Discussion/DiscussionSeven";
import DiscussionEight from "./Components/Discussion/DiscussionEight";

import DocumentUpload from "../pages/DocumentUpload";
import NextSteps from "../pages/NextSteps";
import Submitted from "../pages/Submitted";

const Form = () => {
  const isMounted = useRef(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [formStateMediationSession, dispatch] = useReducer(
    reducer,
    JSON.parse(localStorage.getItem("formStateMediationSession")) ||
      defaultState
  );

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    localStorage.setItem(
      "formStateMediationSession",
      JSON.stringify(formStateMediationSession)
    );
  }, [formStateMediationSession]);

  const {
    currentStepIndex,
    steps,
    step,
    isFirstStep,
    isLastStep,
    back,
    next,
    goTo,
  } = useMultiStepForm([
    <FirstPage state={formStateMediationSession} dispatch={dispatch} />,
    <KeyFacts state={formStateMediationSession} dispatch={dispatch} />,
    <RecordOfMattersDiscussed
      state={formStateMediationSession}
      dispatch={dispatch}
    />,
    <DiscussionOne state={formStateMediationSession} dispatch={dispatch} />,
    <DiscussionTwo state={formStateMediationSession} dispatch={dispatch} />,
    <DiscussionThree state={formStateMediationSession} dispatch={dispatch} />,
    <DiscussionFour state={formStateMediationSession} dispatch={dispatch} />,
    <DiscussionFive state={formStateMediationSession} dispatch={dispatch} />,
    <DiscussionSix state={formStateMediationSession} dispatch={dispatch} />,
    <DiscussionSeven state={formStateMediationSession} dispatch={dispatch} />,
    <DiscussionEight state={formStateMediationSession} dispatch={dispatch} />,
    <DocumentUpload state={formStateMediationSession} dispatch={dispatch} />,
    <NextSteps state={formStateMediationSession} dispatch={dispatch} />,
  ]);

  return (
    <>
      {isSubmitted === true ? (
        <Submitted />
      ) : (
        <FormComponent
          logo={logo}
          currentStepIndex={currentStepIndex}
          formStateMediationSession={formStateMediationSession}
          isFirstStep={isFirstStep}
          isLastStep={isLastStep}
          step={step}
          steps={steps}
          next={next}
          goTo={goTo}
          back={back}
          setIsSubmitted={setIsSubmitted}
        />
      )}
    </>
  );
};

export default Form;
