import { client } from "../axios/axios";

export const patchRecordSession = (
  formStateMediationSession,
  id,
  setIsSubmitted
) => {
  const recordSessionObject = {
    clientData: {
      clientOneFullName: formStateMediationSession.clientOneFullName,
      clientTwoFullName: formStateMediationSession.clientTwoFullName,
      mediatorName: formStateMediationSession.mediatorName,
      sessionDate: formStateMediationSession.sessionDate,
      caseType: formStateMediationSession.caseType,
      specifyLocation: formStateMediationSession.specifyLocation,
      mediationSessionNumber: formStateMediationSession.mediationSessionNumber,
      sessionLength: formStateMediationSession.sessionLength,
    },
    keyFacts: {
      clientsAgreedToAttendMediationChildAndFiance:
        formStateMediationSession.haveChildren,
      children: [],
    },
    recordOfMattersDiscussed: {
      mediationType: formStateMediationSession.mediationTypes,
      clientsAgreedToMediate: formStateMediationSession.agreeToMediate,
      agendaListPoints: formStateMediationSession.agendaPointsList,
    },
    discussions: [
      {
        "Discussion One": {
          firstAgendaPointDiscussed: formStateMediationSession.firstAgenda,
          firstAgendaIssuesIdentified:
            formStateMediationSession.firstAgendaIssues,
          firstAgendaOptionsDiscussed:
            formStateMediationSession.firstAgendaOptions,
          firstAgendaAnyAgreementsReached:
            formStateMediationSession.firstAgendaAgreements,
          firstAgendaClientsActionPoints:
            formStateMediationSession.firstAgendaActionsPoints,
          secondAgendaCheck: formStateMediationSession.secondAgendaCheck,
        },
      },
    ],
    documentUpload: {
      additionalDocumentsToUpload:
        formStateMediationSession.additionalDocuments,
    },
    NextSteps: {
      isFurtherSessionPlanned: formStateMediationSession.isFurtherSession,
      isC100OrFormA: formStateMediationSession.wantToUpload,
      isTwoClientWantCopy: formStateMediationSession.bothClientEmail,
      clientOneEmail: formStateMediationSession.clientOneEmailAddress,
      isMediatorWantCopy: formStateMediationSession.isMediatorEmail,
      MediatorComments: formStateMediationSession.mediatorComments,
    },
  };
  if (recordSessionObject.clientData.specifyLocation === "Other") {
    recordSessionObject.clientData.mediationSessionLocation =
      formStateMediationSession.mediationSessionLocation;
  }
  if (recordSessionObject.clientData.caseType === "Child Issues") {
    recordSessionObject.keyFacts.clientsAgreedToAttendMediationChildChild =
      formStateMediationSession.childIssue;
  } else if (recordSessionObject.clientData.caseType === "Finance & Property") {
    recordSessionObject.keyFacts.clientsAgreedToAttendMediationChildFinance =
      formStateMediationSession.finance;
  } else if (recordSessionObject.clientData.caseType === "All issues") {
    recordSessionObject.keyFacts.clientsAgreedToAttendMediationChildAllIssues =
      formStateMediationSession.allIssues;
  }
  if (
    recordSessionObject.keyFacts
      .clientsAgreedToAttendMediationChildAndFiance === "Yes"
  ) {
    recordSessionObject.keyFacts.children = [
      {
        "Child One": {
          firstChildFirstName: formStateMediationSession.firstChildFullName,
          firstChildDateOfBirth:
            formStateMediationSession.firstChildDateOfBirth,
          bothHaveParentalResponsibilityForFirstChild:
            formStateMediationSession.firstChildBothParentalResponsibility,
          secondChildCheck: formStateMediationSession.secondChildCheck,
        },
      },
    ];
  }
  if (
    recordSessionObject.keyFacts?.children[0]?.["Child One"]
      .bothHaveParentalResponsibilityForFirstChild === "No"
  ) {
    recordSessionObject.keyFacts.children[0][
      "Child One"
    ].firstChildParentalResponsibility =
      formStateMediationSession.firstChildParentalResponsibility;
  }
  if (
    recordSessionObject.keyFacts?.children[0]?.["Child One"]
      .secondChildCheck === "Yes"
  ) {
    recordSessionObject.keyFacts.children.push({
      "Child Two": {
        secondChildFullName: formStateMediationSession.SecondChildFullName,
        secondChildDateOfBirth:
          formStateMediationSession.SecondChildDateOfBirth,
        secondChildBothParentalResponsibility:
          formStateMediationSession.secondChildBothParentalResponsibility,
        thirdChildCheck: formStateMediationSession.thirdChildCheck,
      },
    });
  }
  if (
    recordSessionObject.keyFacts?.children[1]?.["Child Two"]
      .secondChildBothParentalResponsibility === "No"
  ) {
    recordSessionObject.keyFacts.children[1][
      "Child Two"
    ].SecondChildParentalResponsibility =
      formStateMediationSession.SecondChildParentalResponsibility;
  }
  if (
    recordSessionObject.keyFacts?.children[1]?.["Child Two"].thirdChildCheck ===
    "Yes"
  ) {
    recordSessionObject.keyFacts.children.push({
      "Child Three": {
        thirdChildFullName: formStateMediationSession.thirdChildFullName,
        thirdChildDateOfBirth: formStateMediationSession.thirdChildDateOfBirth,
        thirdChildBothParentalResponsibility:
          formStateMediationSession.thirdChildBothParentalResponsibility,
        fourthChildCheck: formStateMediationSession.fourthChildCheck,
      },
    });
  }
  if (
    recordSessionObject.keyFacts?.children[2]?.["Child Three"]
      .thirdChildBothParentalResponsibility === "No"
  ) {
    recordSessionObject.keyFacts.children[2][
      "Child Three"
    ].thirdChildParentalResponsibility =
      formStateMediationSession.thirdChildParentalResponsibility;
  }
  if (
    recordSessionObject.keyFacts?.children[2]?.["Child Three"]
      .fourthChildCheck === "Yes"
  ) {
    recordSessionObject.keyFacts.children.push({
      "Child Four": {
        fourthChildFullName: formStateMediationSession.fourthChildFullName,
        fourthChildDateOfBirth:
          formStateMediationSession.fourthChildDateOfBirth,
        fourthChildBothParentalResponsibility:
          formStateMediationSession.fourthChildBothParentalResponsibility,
      },
    });
  }
  if (
    recordSessionObject.keyFacts?.children[3]?.["Child Four"]
      .fourthChildBothParentalResponsibility === "No"
  ) {
    recordSessionObject.keyFacts.children[3][
      "Child Four"
    ].fourthChildParentalResponsibility =
      formStateMediationSession.fourthChildParentalResponsibility;
  }
  if (
    recordSessionObject.discussions[0]?.["Discussion One"]
      .anyAgreementsReached === "Yes"
  ) {
    recordSessionObject.discussions[0]["Discussion One"].agreedBulletsPoints =
      formStateMediationSession.firstAgendaAgreementsPoints;
  }
  if (
    recordSessionObject.discussions[0]?.["Discussion One"].secondAgendaCheck ===
    "Yes"
  ) {
    recordSessionObject.discussions.push({
      "Discussion Two": {
        secondAgendaPointDiscussed: formStateMediationSession.secondAgenda,
        secondAgendaIssuesIdentified:
          formStateMediationSession.secondAgendaIssues,
        secondAgendaOptionsDiscussed:
          formStateMediationSession.secondAgendaOptions,
        secondAgendaAgreementsReached:
          formStateMediationSession.secondAgendaAgreements,
        secondAgendaActionsPoints:
          formStateMediationSession.secondAgendaActionsPoints,
        thirdAgendaCheck: formStateMediationSession.thirdAgendaCheck,
      },
    });
  }
  if (
    recordSessionObject.discussions[1]?.["Discussion Two"]
      .secondAgendaAgreementsReached === "Yes"
  ) {
    recordSessionObject.discussions[1][
      "Discussion Two"
    ].secondAgendaAgreementsPoints =
      formStateMediationSession.secondAgendaAgreementsPoints;
  }
  if (
    recordSessionObject.discussions[1]?.["Discussion Two"].thirdAgendaCheck ===
    "Yes"
  ) {
    recordSessionObject.discussions.push({
      "Discussion Three": {
        thirdAgendaPointDiscussed: formStateMediationSession.thirdAgenda,
        thirdAgendaIssuesIdentified:
          formStateMediationSession.thirdAgendaIssues,
        thirdAgendaOptionsDiscussed:
          formStateMediationSession.thirdAgendaOptions,
        thirdAgendaAgreementsReached:
          formStateMediationSession.thirdAgendaAgreements,
        thirdAgendaActionsPoints:
          formStateMediationSession.thirdAgendaActionsPoints,
        fourthAgendaCheck: formStateMediationSession.fourthAgendaCheck,
      },
    });
  }
  if (
    recordSessionObject.discussions[2]?.["Discussion Three"]
      .thirdAgendaAgreementsReached === "Yes"
  ) {
    recordSessionObject.discussions[2][
      "Discussion Three"
    ].thirdAgendaAgreedBulletsPoints =
      formStateMediationSession.thirdAgendaAgreementsPoints;
  }
  if (
    recordSessionObject.discussions[2]?.["Discussion Three"]
      .fourthAgendaCheck === "Yes"
  ) {
    recordSessionObject.discussions.push({
      "Discussion Four": {
        fourthAgendaPointDiscussed: formStateMediationSession.fourthAgenda,
        fourthAgendaIssuesIdentified:
          formStateMediationSession.fourthAgendaIssues,
        fourthAgendaOptionsDiscussed:
          formStateMediationSession.fourthAgendaOptions,
        fourthAgendaAgreementsReached:
          formStateMediationSession.fourthAgendaAgreements,
        fourthAgendaActionsPoints:
          formStateMediationSession.fourthAgendaActionsPoints,
        fifthAgendaCheck: formStateMediationSession.fifthAgendaCheck,
      },
    });
  }
  if (
    recordSessionObject.discussions[3]?.["Discussion Four"]
      .fourthAgendaAgreementsReached === "Yes"
  ) {
    recordSessionObject.discussions[3][
      "Discussion Four"
    ].fourthAgendaAgreedBulletsPoints =
      formStateMediationSession.fourthAgendaAgreementsPoints;
  }
  if (
    recordSessionObject.discussions[3]?.["Discussion Four"].fifthAgendaCheck ===
    "Yes"
  ) {
    recordSessionObject.discussions.push({
      "Discussion Five": {
        fifthAgendaPointDiscussed: formStateMediationSession.fifthAgenda,
        fifthAgendaIssuesIdentified:
          formStateMediationSession.fifthAgendaIssues,
        fifthAgendaOptionsDiscussed:
          formStateMediationSession.fifthAgendaOptions,
        fifthAgendaAgreementsReached:
          formStateMediationSession.fifthAgendaAgreements,
        fifthAgendaActionsPoints:
          formStateMediationSession.fifthAgendaActionsPoints,
        sixthAgendaCheck: formStateMediationSession.sixthAgendaCheck,
      },
    });
  }
  if (
    recordSessionObject.discussions[4]?.["Discussion Five"]
      .fifthAgendaAgreementsReached === "Yes"
  ) {
    recordSessionObject.discussions[4][
      "Discussion Five"
    ].fifthAgendaAgreedBulletsPoints =
      formStateMediationSession.fifthAgendaAgreementsPoints;
  }
  if (
    recordSessionObject.discussions[4]?.["Discussion Five"].sixthAgendaCheck ===
    "Yes"
  ) {
    recordSessionObject.discussions.push({
      "Discussion Six": {
        sixthAgendaPointDiscussed: formStateMediationSession.sixthAgenda,
        sixthAgendaIssuesIdentified:
          formStateMediationSession.sixthAgendaIssues,
        sixthAgendaOptionsDiscussed:
          formStateMediationSession.sixthAgendaOptions,
        sixthAgendaAgreementsReached:
          formStateMediationSession.sixthAgendaAgreements,
        sixthAgendaActionsPoints:
          formStateMediationSession.sixthAgendaActionsPoints,
        seventhAgendaCheck: formStateMediationSession.seventhAgendaCheck,
      },
    });
  }
  if (
    recordSessionObject.discussions[5]?.["Discussion Six"]
      .sixthAgendaAgreementsReached === "Yes"
  ) {
    recordSessionObject.discussions[5][
      "Discussion Six"
    ].sixthAgendaAgreedBulletsPoints =
      formStateMediationSession.sixthAgendaAgreementsPoints;
  }
  if (
    recordSessionObject.discussions[5]?.["Discussion Six"]
      .seventhAgendaCheck === "Yes"
  ) {
    recordSessionObject.discussions.push({
      "Discussion Seven": {
        seventhAgendaPointDiscussed: formStateMediationSession.seventhAgenda,
        seventhAgendaIssuesIdentified:
          formStateMediationSession.seventhAgendaIssues,
        seventhAgendaOptionsDiscussed:
          formStateMediationSession.seventhAgendaOptions,
        seventhAgendaAgreementsReached:
          formStateMediationSession.seventhAgendaAgreements,
        seventhAgendaActionsPoints:
          formStateMediationSession.seventhAgendaActionsPoints,
        eighthAgendaCheck: formStateMediationSession.eighthAgendaCheck,
      },
    });
  }
  if (
    recordSessionObject.discussions[6]?.["Discussion Seven"]
      .seventhAgendaAgreementsReached === "Yes"
  ) {
    recordSessionObject.discussions[6][
      "Discussion Seven"
    ].seventhAgendaAgreedBulletsPoints =
      formStateMediationSession.seventhAgendaAgreementsPoints;
  }
  if (
    recordSessionObject.discussions[6]?.["Discussion Seven"]
      .eighthAgendaCheck === "Yes"
  ) {
    recordSessionObject.discussions.push({
      "Discussion Eight": {
        eighthAgendaPointDiscussed: formStateMediationSession.eighthAgenda,
        eighthAgendaIssuesIdentified:
          formStateMediationSession.eighthAgendaIssues,
        eighthAgendaOptionsDiscussed:
          formStateMediationSession.eighthAgendaOptions,
        eighthAgendaAgreementsReached:
          formStateMediationSession.eighthAgendaAgreements,
        eighthAgendaActionsPoints:
          formStateMediationSession.eighthAgendaActionsPoints,
      },
    });
  }
  if (
    recordSessionObject.discussions[7]?.["Discussion Eight"]
      .eighthAgendaAgreementsReached === "Yes"
  ) {
    recordSessionObject.discussions[7][
      "Discussion Eight"
    ].eighthAgendaAgreedBulletsPoints =
      formStateMediationSession.eighthAgendaAgreementsPoints;
  }
  if (
    recordSessionObject.documentUpload.additionalDocumentsToUpload === "Yes"
  ) {
    recordSessionObject.documentUpload.documentsPaths = ["paths"];
  }
  if (recordSessionObject.NextSteps.isFurtherSessionPlanned === "Yes") {
    recordSessionObject.NextSteps.bothClientsAgreed =
      formStateMediationSession.bothClientsAgreed;
    if (
      recordSessionObject.NextSteps.isFurtherSessionPlanned === "Considering"
    ) {
      recordSessionObject.NextSteps.nextSessionIssues =
        formStateMediationSession.nextSessionIssues;
    }
    if (recordSessionObject.NextSteps.bothClientsAgreed === "Yes") {
      recordSessionObject.NextSteps.returningToMediationDate =
        formStateMediationSession.returningToMediationDate;
      recordSessionObject.NextSteps.appointmentTime =
        formStateMediationSession.appointmentTime;
    }
  } else if (recordSessionObject.NextSteps.isFurtherSessionPlanned === "No") {
    recordSessionObject.NextSteps.mediationFinishedReason =
      formStateMediationSession.mediationFinishedReason;
  }
  if (recordSessionObject.NextSteps.isTwoClientWantCopy === "Yes") {
    recordSessionObject.NextSteps.clientTwoEmail =
      formStateMediationSession.clientTwoEmailAddress;
  }
  if (recordSessionObject.NextSteps.isMediatorWantCopy === "Yes") {
    recordSessionObject.NextSteps.mediatorEmail =
      formStateMediationSession.mediatorEmailAddress;
  }
  client
    .patch(`/addMediationRecord/${id}`, recordSessionObject, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      console.log({ resData: res.data });
      setIsSubmitted(true);
    })
    .catch((err) => {
      console.log({ err: err.message });
    });
};
