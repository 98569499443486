import { useState } from "react";
import useScrollTo from "../../../hooks/useScrollTo";
import TextInput from "../TextInput";
import YesNoInput from "../YesNoInput";
import TextArea from "../TextArea";

const DiscussionOne = ({ state, dispatch }) => {
  const [firstAgenda, setFirstAgenda] = useState(state.firstAgenda || "");
  const [firstAgendaIssues, setFirstAgendaIssues] = useState(
    state.firstAgendaIssues || ""
  );
  const [firstAgendaOptions, setFirstAgendaOptions] = useState(
    state.firstAgendaOptions || ""
  );
  const [firstAgendaAgreements, setFirstAgendaAgreements] = useState(
    state.firstAgendaAgreements
  );
  const [firstAgendaAgreementsPoints, setFirstAgendaAgreementsPoints] =
    useState(state.firstAgendaAgreementsPoints || "");
  const [firstAgendaActionsPoints, setFirstAgendaActionsPoints] = useState(
    state.firstAgendaActionsPoints || ""
  );
  const [secondAgendaCheck, setSecondAgendaCheck] = useState(
    state.secondAgendaCheck
  );

  useScrollTo(0, 0, "smooth");

  const onFirstAgendaChange = ({ target }) => {
    setFirstAgenda(target.value);
    dispatch({ type: "FIRST_AGENDA", payload: target.value });
  };
  const onFirstAgendaIssuesChange = ({ target }) => {
    setFirstAgendaIssues(target.value);
    dispatch({ type: "FIRST_AGENDA_ISSUES", payload: target.value });
  };
  const onFirstAgendaOptionsChange = ({ target }) => {
    setFirstAgendaOptions(target.value);
    dispatch({ type: "FIRST_AGENDA_OPTIONS", payload: target.value });
  };
  const onFirstAgendaAgreementsChange = ({ target }) => {
    setFirstAgendaAgreements(target.value);
    dispatch({ type: "FIRST_AGENDA_AGREEMENTS", payload: target.value });
  };
  const onFirstAgendaAgreementsPointsChange = ({ target }) => {
    setFirstAgendaAgreementsPoints(target.value);
    dispatch({ type: "FIRST_AGENDA_AGREEMENTS_POINTS", payload: target.value });
  };
  const onFirstAgendaActionsPointsChange = ({ target }) => {
    setFirstAgendaActionsPoints(target.value);
    dispatch({ type: "FIRST_AGENDA_ACTIONS_POINTS", payload: target.value });
  };
  const onSecondAgendaCheck = ({ target }) => {
    setSecondAgendaCheck(target.value);
    dispatch({ type: "SECOND_AGENDA_CHECK", payload: target.value });
  };

  return (
    <>
      <h2>Discussion - Point 1</h2>
      <TextInput
        label="First agenda point discussed."
        id="first_agenda"
        onChangeText={onFirstAgendaChange}
        value={firstAgenda}
        required={true}
      />
      <TextArea
        label="Issues identified"
        id="issues-identified"
        onTextChange={onFirstAgendaIssuesChange}
        value={firstAgendaIssues}
        required={true}
        rows={8}
        guide="Please add details and context about the issues discussed."
      />
      <TextArea
        label="Options discussed"
        id="options-discussed"
        onTextChange={onFirstAgendaOptionsChange}
        value={firstAgendaOptions}
        required={true}
        rows={8}
        guide="List the proposals put forward by the participants."
      />
      <YesNoInput
        label="Any agreements reached?"
        name="agreements-reached"
        checkedTerm={firstAgendaAgreements}
        onOptionChange={onFirstAgendaAgreementsChange}
        required={true}
        noId="no-agreements-reached"
        noLabel="No"
        yesId="yes-agreements-reached"
        yesLabel="Yes"
      />
      {firstAgendaAgreements === "Yes" && (
        <TextArea
          label="Please bullet point any agreements reached"
          name="agreement-points"
          id="agreement-points"
          value={firstAgendaAgreementsPoints}
          onTextChange={onFirstAgendaAgreementsPointsChange}
          guide="Ensure there is enough detail to stablish a clear agreement."
          cols={8}
          required={true}
        />
      )}
      <TextInput
        label="Action points for the clients"
        name="action-points"
        id="action-points"
        guide="Please specify any practical activities/actions that need to take place to allow the agreements to be reached."
        value={firstAgendaActionsPoints}
        onChangeText={onFirstAgendaActionsPointsChange}
        required={true}
      />
      <YesNoInput
        label="Is there a second agenda point?"
        checkedTerm={secondAgendaCheck}
        onOptionChange={onSecondAgendaCheck}
        name="second-agenda"
        required={true}
        noId="no-second-agenda"
        noLabel="No"
        yesId="yes-second-agenda"
        yesLabel="Yes"
      />
    </>
  );
};

export default DiscussionOne;
