import { useState } from "react";
import useScrollTo from "../../../hooks/useScrollTo";
import TextInput from "../TextInput";
import YesNoInput from "../YesNoInput";
import TextArea from "../TextArea";

const DiscussionTwo = ({ state, dispatch }) => {
  const [secondAgenda, setSecondAgenda] = useState(state.secondAgenda || "");
  const [secondAgendaIssues, setSecondAgendaIssues] = useState(
    state.secondAgendaIssues || ""
  );
  const [secondAgendaOptions, setSecondAgendaOptions] = useState(
    state.secondAgendaOptions || ""
  );
  const [secondAgendaAgreements, setSecondAgendaAgreements] = useState(
    state.secondAgendaAgreements
  );
  const [secondAgendaAgreementsPoints, setSecondAgendaAgreementsPoints] =
    useState(state.secondAgendaAgreementsPoints || "");
  const [secondAgendaActionsPoints, setSecondAgendaActionsPoints] = useState(
    state.secondAgendaActionsPoints || ""
  );
  const [thirdAgendaCheck, setThirdAgendaCheck] = useState(
    state.thirdAgendaCheck
  );

  useScrollTo(0, 0, "smooth");

  const onSecondAgendaChange = ({ target }) => {
    setSecondAgenda(target.value);
    dispatch({ type: "SECOND_AGENDA", payload: target.value });
  };
  const onSecondAgendaIssuesChange = ({ target }) => {
    setSecondAgendaIssues(target.value);
    dispatch({ type: "SECOND_AGENDA_ISSUES", payload: target.value });
  };
  const onSecondAgendaOptionsChange = ({ target }) => {
    setSecondAgendaOptions(target.value);
    dispatch({ type: "SECOND_AGENDA_OPTIONS", payload: target.value });
  };
  const onSecondAgendaAgreementsChange = ({ target }) => {
    setSecondAgendaAgreements(target.value);
    dispatch({ type: "SECOND_AGENDA_AGREEMENTS", payload: target.value });
  };
  const onSecondAgendaAgreementsPointsChange = ({ target }) => {
    setSecondAgendaAgreementsPoints(target.value);
    dispatch({
      type: "SECOND_AGENDA_AGREEMENTS_POINTS",
      payload: target.value,
    });
  };
  const onSecondAgendaActionsPointsChange = ({ target }) => {
    setSecondAgendaActionsPoints(target.value);
    dispatch({ type: "SECOND_AGENDA_ACTIONS_POINTS", payload: target.value });
  };
  const onThirdAgendaCheck = ({ target }) => {
    setThirdAgendaCheck(target.value);
    dispatch({ type: "THIRD_AGENDA_CHECK", payload: target.value });
  };

  return (
    <>
      <h2>Discussion - Point 2</h2>
      <TextInput
        label="Second agenda point discussed."
        id="second_agenda"
        onChangeText={onSecondAgendaChange}
        value={secondAgenda}
        required={true}
      />
      <TextArea
        rows={8}
        label="Issues identified"
        id="issues-identified"
        onTextChange={onSecondAgendaIssuesChange}
        value={secondAgendaIssues}
        required={true}
        guide="Please add details and context about the issues discussed."
      />
      <TextArea
        rows={8}
        label="Options discussed"
        id="options-discussed"
        onTextChange={onSecondAgendaOptionsChange}
        value={secondAgendaOptions}
        required={true}
        guide="List the proposals put forward by the participants."
      />
      <YesNoInput
        label="Any agreements reached?"
        name="agreements-reached"
        checkedTerm={secondAgendaAgreements}
        onOptionChange={onSecondAgendaAgreementsChange}
        required={true}
        noId="no-agreements-reached"
        noLabel="No"
        yesId="yes-agreements-reached"
        yesLabel="Yes"
      />
      {secondAgendaAgreements === "Yes" && (
        <TextArea
          label="Please bullet point any agreements reached"
          name="agreement-points"
          id="agreement-points"
          rows={8}
          value={secondAgendaAgreementsPoints}
          onTextChange={onSecondAgendaAgreementsPointsChange}
          guide="Ensure there is enough detail to stablish a clear agreement."
          required={true}
        />
      )}
      <TextInput
        label="Action points for the clients"
        name="action-points"
        id="action-points"
        guide="Please specify any practical activities/actions that need to take place to allow the agreements to be reached."
        value={secondAgendaActionsPoints}
        onChangeText={onSecondAgendaActionsPointsChange}
        required={true}
      />
      <YesNoInput
        label="Is there a third agenda point?"
        checkedTerm={thirdAgendaCheck}
        onOptionChange={onThirdAgendaCheck}
        name="third-agenda"
        required={true}
        noId="no-third-agenda"
        noLabel="No"
        yesId="yes-third-agenda"
        yesLabel="Yes"
      />
    </>
  );
};

export default DiscussionTwo;
