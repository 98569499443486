import { useState } from "react";
import useScrollTo from "../../../hooks/useScrollTo";
import TextInput from "../TextInput";
import YesNoInput from "../YesNoInput";
import TextArea from "../TextArea";

const DiscussionFour = ({ state, dispatch }) => {
  const [fourthAgenda, setFourthAgenda] = useState(state.fourthAgenda || "");
  const [fourthAgendaIssues, setFourthAgendaIssues] = useState(
    state.fourthAgendaIssues || ""
  );
  const [fourthAgendaOptions, setFourthAgendaOptions] = useState(
    state.fourthAgendaOptions || ""
  );
  const [fourthAgendaAgreements, setFourthAgendaAgreements] = useState(
    state.fourthAgendaAgreements
  );
  const [fourthAgendaAgreementsPoints, setFourthAgendaAgreementsPoints] =
    useState(state.fourthAgendaAgreementsPoints || "");
  const [fourthAgendaActionsPoints, setFourthAgendaActionsPoints] = useState(
    state.fourthAgendaActionsPoints || ""
  );
  const [fifthAgendaCheck, setFifthAgendaCheck] = useState(
    state.fifthAgendaCheck
  );

  useScrollTo(0, 0, "smooth");

  const onFourthAgendaChange = ({ target }) => {
    setFourthAgenda(target.value);
    dispatch({ type: "FOURTH_AGENDA", payload: target.value });
  };
  const onFourthAgendaIssuesChange = ({ target }) => {
    setFourthAgendaIssues(target.value);
    dispatch({ type: "FOURTH_AGENDA_ISSUES", payload: target.value });
  };
  const onFourthAgendaOptionsChange = ({ target }) => {
    setFourthAgendaOptions(target.value);
    dispatch({ type: "FOURTH_AGENDA_OPTIONS", payload: target.value });
  };
  const onFourthAgendaAgreementsChange = ({ target }) => {
    setFourthAgendaAgreements(target.value);
    dispatch({ type: "FOURTH_AGENDA_AGREEMENTS", payload: target.value });
  };
  const onFourthAgendaAgreementsPointsChange = ({ target }) => {
    setFourthAgendaAgreementsPoints(target.value);
    dispatch({
      type: "FOURTH_AGENDA_AGREEMENTS_POINTS",
      payload: target.value,
    });
  };
  const onFourthAgendaActionsPointsChange = ({ target }) => {
    setFourthAgendaActionsPoints(target.value);
    dispatch({ type: "FOURTH_AGENDA_ACTIONS_POINTS", payload: target.value });
  };
  const onFifthAgendaCheck = ({ target }) => {
    setFifthAgendaCheck(target.value);
    dispatch({ type: "FIFTH_AGENDA_CHECK", payload: target.value });
  };
  return (
    <>
      <h2>Discussion - Point 4</h2>
      <TextInput
        label="Fourth agenda point discussed."
        id="fourth_agenda"
        onChangeText={onFourthAgendaChange}
        value={fourthAgenda}
        required={true}
      />
      <TextArea
        rows={8}
        label="Issues identified"
        id="issues-identified"
        onTextChange={onFourthAgendaIssuesChange}
        value={fourthAgendaIssues}
        required={true}
        guide="Please add details and context about the issues discussed."
      />
      <TextArea
        rows={8}
        label="Options discussed"
        id="options-discussed"
        onTextChange={onFourthAgendaOptionsChange}
        value={fourthAgendaOptions}
        required={true}
        guide="List the proposals put forward by the participants."
      />
      <YesNoInput
        label="Any agreements reached?"
        name="agreements-reached"
        checkedTerm={fourthAgendaAgreements}
        onOptionChange={onFourthAgendaAgreementsChange}
        required={true}
        noId="no-agreements-reached"
        noLabel="No"
        yesId="yes-agreements-reached"
        yesLabel="Yes"
      />
      {fourthAgendaAgreements === "Yes" && (
        <TextArea
          rows={8}
          label="Please bullet point any agreements reached"
          name="agreement-points"
          id="agreement-points"
          value={fourthAgendaAgreementsPoints}
          onTextChange={onFourthAgendaAgreementsPointsChange}
          guide="Ensure there is enough detail to stablish a clear agreement."
          required={true}
        />
      )}
      <TextInput
        label="Action points for the clients"
        name="action-points"
        id="action-points"
        guide="Please specify any practical activities/actions that need to take place to allow the agreements to be reached."
        value={fourthAgendaActionsPoints}
        onChangeText={onFourthAgendaActionsPointsChange}
        required={true}
      />
      <YesNoInput
        label="Is there a fifth agenda point?"
        checkedTerm={fifthAgendaCheck}
        onOptionChange={onFifthAgendaCheck}
        name="fifth-agenda"
        required={true}
        noId="no-fifth-agenda"
        noLabel="No"
        yesId="yes-fifth-agenda"
        yesLabel="Yes"
      />
    </>
  );
};

export default DiscussionFour;
