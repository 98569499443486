import React, { useState } from "react";
import TextInput from "../Components/TextInput";
import SelectInput from "../Components/SelectInput";
import YesNoInput from "../Components/YesNoInput";

const parentalResponsibilityOptions = ["Yes", "No"];

const FirstChild = ({ state, dispatch }) => {
  const [firstChildFullName, setFirstChildFullName] = useState(
    state.firstChildFullName || ""
  );
  const [firstChildDateOfBirth, setFirstChildDateOfBirth] = useState(
    state.firstChildDateOfBirth || ""
  );
  const [
    firstChildBothParentalResponsibility,
    setFirstChildBothParentalResponsibility,
  ] = useState(state.firstChildBothParentalResponsibility || "select");
  const [
    firstChildParentalResponsibility,
    setFirstChildParentalResponsibility,
  ] = useState(state.firstChildParentalResponsibility || "");
  const [secondChildCheck, setSecondChildCheck] = useState(
    state.secondChildCheck
  );

  const onFirstChildFullNameChange = ({ target }) => {
    setFirstChildFullName(target.value);
    dispatch({ type: "FIRST_CHILD_FULL_NAME", payload: target.value });
  };
  const onFirstChildDateOfBirthChange = ({ target }) => {
    setFirstChildDateOfBirth(target.value);
    dispatch({ type: "FIRST_CHILD_DATE_OF_BIRTH", payload: target.value });
  };
  const onFirstChildBothParentalResponsibilityChange = ({ target }) => {
    setFirstChildBothParentalResponsibility(target.value);
    dispatch({
      type: "FIRST_CHILD_BOTH_PARENTAL_RESPONSIBILITY",
      payload: target.value,
    });
  };
  const onFirstChildParentalResponsibilityChange = ({ target }) => {
    setFirstChildParentalResponsibility(target.value);
    dispatch({
      type: "FIRST_CHILD_PARENTAL_RESPONSIBILITY",
      payload: target.value,
    });
  };
  const onSecondChildCheckChange = ({ target }) => {
    setSecondChildCheck(target.value);
    dispatch({ type: "SECOND_CHILD_CHECK", payload: target.value });
  };
  return (
    <>
      <TextInput
        label="What is the full name of the first child?"
        required={true}
        value={firstChildFullName}
        onChangeText={onFirstChildFullNameChange}
        id="firstChildFullName"
      />
      <div className="form-control colum-direction">
        <label htmlFor="date-of-birth">
          What is {firstChildFullName}’s date of birth?
          <span className="color-red">*</span>
        </label>
        <input
          type="date"
          name="first-child-dob"
          id="first-child-date-of-birth"
          value={firstChildDateOfBirth}
          onChange={onFirstChildDateOfBirthChange}
          required
          max={new Date().toLocaleDateString("fr-ca")}
        />
      </div>
      <SelectInput
        label={`Do both participants have Parental Responsibility for ${firstChildFullName}?`}
        options={parentalResponsibilityOptions}
        onOptionChange={onFirstChildBothParentalResponsibilityChange}
        optionValue={firstChildBothParentalResponsibility}
        required={true}
      />
      {firstChildBothParentalResponsibility === "No" && (
        <TextInput
          label={`Who has parental responsibility for ${firstChildFullName}?`}
          value={firstChildParentalResponsibility}
          onChangeText={onFirstChildParentalResponsibilityChange}
          id="firstChildParentalResponsibility"
          required={true}
        />
      )}
      <YesNoInput
        label={`Do ${state.clientOneFullName} and ${state.clientTwoFullName} have a second child?`}
        name={"secondChildCheck"}
        checkedTerm={secondChildCheck}
        onOptionChange={onSecondChildCheckChange}
        required={true}
        noId="no-second-child"
        yesId="yes-second-child"
        noLabel="No"
        yesLabel="Yes"
      />
    </>
  );
};

export default FirstChild;
