import { useState } from "react";
import TextInput from "../FormComponents/Components/TextInput";
import useScrollTo from "../hooks/useScrollTo";
import SelectInput from "../FormComponents/Components/SelectInput";

const caseOptions = ["Child Issues", "Finance & Property", "All issues"];
const specifyLocationOptions = ["Online", "Other"];
const specifySessionsNumberOptions = [
  "Session 1",
  "Session 2",
  "Session 3",
  "Session 4",
  "Session 5",
];

const FirstPage = ({ state, dispatch }) => {
  const [clientOneFullName, setClientOneFullName] = useState(
    state.clientOneFullName || ""
  );
  const [clientTwoFullName, setClientTwoFullName] = useState(
    state.clientTwoFullName || ""
  );
  const [mediatorName, setMediatorName] = useState(state.mediatorName || "");
  const [sessionDate, setSessionDate] = useState(state.sessionDate || "");
  const [caseType, setCaseType] = useState(state.caseType || "select");
  const [specifyLocation, setSpecifyLocation] = useState(
    state.specifyLocation || "select"
  );
  const [mediationSessionLocation, setMediationSessionLocation] = useState(
    state.mediationSessionLocation || ""
  );
  const [mediationSessionNumber, setMediationSessionNumber] = useState(
    state.mediationSessionNumber || "select"
  );
  const [sessionLength, setSessionLength] = useState(state.sessionLength || "");

  useScrollTo(0, 0, "smooth");

  const onClientOneFullNameChange = ({ target }) => {
    setClientOneFullName(target.value);
    dispatch({ type: "CLIENT_ONE_FULL_NAME", payload: target.value });
  };
  const onClientTwoFullNameChange = ({ target }) => {
    setClientTwoFullName(target.value);
    dispatch({ type: "CLIENT_TWO_FULL_NAME", payload: target.value });
  };
  const onMediationNameChange = ({ target }) => {
    setMediatorName(target.value);
    dispatch({ type: "MEDIATOR_NAME", payload: target.value });
  };
  const onSessionDateChange = ({ target }) => {
    setSessionDate(target.value);
    dispatch({ type: "SESSION_DATE", payload: target.value });
  };
  const onCaseTypeChange = ({ target }) => {
    setCaseType(target.value);
    dispatch({ type: "CASE_TYPE", payload: target.value });
  };
  const onSpecifyLocationChange = ({ target }) => {
    setSpecifyLocation(target.value);
    dispatch({ type: "SPECIFY_LOCATION", payload: target.value });
  };
  const onMediationSessionLocationChange = ({ target }) => {
    setMediationSessionLocation(target.value);
    dispatch({ type: "MEDIATION_SESSION_LOCATION", payload: target.value });
  };
  const onMediationSessionNumberChange = ({ target }) => {
    setMediationSessionNumber(target.value);
    dispatch({ type: "MEDIATION_SESSION_NUMBER", payload: target.value });
  };
  const onSessionLengthChange = ({ target }) => {
    setSessionLength(target.value);
    dispatch({ type: "SESSION_LENGTH", payload: target.value });
  };

  return (
    <>
      <h1 className="text-center">DIRECT MEDIATION SERVICES</h1>
      <h3 className="text-center">Mediation Session Record</h3>
      <p className="text-center">
        <strong>To be completed by the mediator</strong>
      </p>
      <TextInput
        label="What is the full name of Client 1?"
        required={true}
        id="clientOneFullName"
        value={clientOneFullName}
        onChangeText={onClientOneFullNameChange}
      />
      <TextInput
        label="What is the full name of Client 2?"
        required={true}
        id="clientTwoFullName"
        value={clientTwoFullName}
        onChangeText={onClientTwoFullNameChange}
      />
      <TextInput
        label="Name of the mediator"
        required={true}
        value={mediatorName}
        onChangeText={onMediationNameChange}
        id="mediationName"
      />
      <div className="form-control colum-direction">
        <label htmlFor="date-of-birth">
          Date of the session<span className="color-red">*</span>
        </label>
        <input
          type="date"
          name="dob"
          id="date-of-birth"
          value={sessionDate}
          onChange={onSessionDateChange}
          required
          max={new Date().toLocaleDateString("fr-ca")}
        />
      </div>
      <SelectInput
        label="Type of case."
        required={true}
        options={caseOptions}
        optionValue={caseType}
        onOptionChange={onCaseTypeChange}
      />
      <SelectInput
        label="Please specify location"
        required={true}
        options={specifyLocationOptions}
        onOptionChange={onSpecifyLocationChange}
        optionValue={specifyLocation}
      />
      {specifyLocation === "Other" && (
        <TextInput
          label="Please provide the location of the mediation session"
          value={mediationSessionLocation}
          onChangeText={onMediationSessionLocationChange}
          required={true}
          id="mediationSession"
        />
      )}
      <SelectInput
        label="Specify the mediation session number"
        options={specifySessionsNumberOptions}
        optionValue={mediationSessionNumber}
        onOptionChange={onMediationSessionNumberChange}
        required={true}
      />
      <TextInput
        label="Length of session "
        boldLabel="plus length of writing (in minutes)"
        value={sessionLength}
        onChangeText={onSessionLengthChange}
        id="sessionLength"
      />
    </>
  );
};

export default FirstPage;
