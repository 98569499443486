import { Route, Routes } from "react-router-dom";
import Form from "../FormComponents/Form";

function App() {
  return (
    <>
      <Routes>
        <Route path="/RecordSessionForm/:id" element={<Form />} />
        <Route path="/" element={<Form />} />
      </Routes>
    </>
  );
}

export default App;
