import React, { useState } from "react";
import TextInput from "../Components/TextInput";
import SelectInput from "../Components/SelectInput";
import YesNoInput from "../Components/YesNoInput";

const parentalResponsibilityOptions = ["Yes", "No"];

const ThirdChild = ({ state, dispatch }) => {
  const [thirdChildFullName, setThirdChildFullName] = useState(
    state.thirdChildFullName || ""
  );
  const [thirdChildDateOfBirth, setThirdChildDateOfBirth] = useState(
    state.thirdChildDateOfBirth || ""
  );
  const [
    thirdChildBothParentalResponsibility,
    setThirdChildBothParentalResponsibility,
  ] = useState(state.thirdChildBothParentalResponsibility || "select");
  const [
    thirdChildParentalResponsibility,
    setThirdChildParentalResponsibility,
  ] = useState(state.thirdChildParentalResponsibility || "");
  const [fourthChildCheck, setFourthChildCheck] = useState(
    state.fourthChildCheck
  );

  const onThirdChildFullNameChange = ({ target }) => {
    setThirdChildFullName(target.value);
    dispatch({ type: "THIRD_CHILD_FULL_NAME", payload: target.value });
  };
  const onThirdChildDateOfBirthChange = ({ target }) => {
    setThirdChildDateOfBirth(target.value);
    dispatch({ type: "THIRD_CHILD_DATE_OF_BIRTH", payload: target.value });
  };
  const onThirdChildBothParentalResponsibilityChange = ({ target }) => {
    setThirdChildBothParentalResponsibility(target.value);
    dispatch({
      type: "THIRD_CHILD_PARENTAL_RESPONSIBILITY_BOTH",
      payload: target.value,
    });
  };
  const onThirdChildParentalResponsibilityChange = ({ target }) => {
    setThirdChildParentalResponsibility(target.value);
    dispatch({
      type: "THIRD_CHILD_PARENTAL_RESPONSIBILITY",
      payload: target.value,
    });
  };
  const onFourthChildCheckChange = ({ target }) => {
    setFourthChildCheck(target.value);
    dispatch({ type: "FOURTH_CHILD_CHECK", payload: target.value });
  };
  return (
    <>
      <TextInput
        label="What is the full name of the Third child?"
        required={true}
        value={thirdChildFullName}
        onChangeText={onThirdChildFullNameChange}
        id="ThirdChildFullName"
      />
      <div className="form-control colum-direction">
        <label htmlFor="date-of-birth">
          What is {thirdChildFullName}’s date of birth?
          <span className="color-red">*</span>
        </label>
        <input
          type="date"
          name="Third-child-dob"
          id="Third-child-date-of-birth"
          value={thirdChildDateOfBirth}
          onChange={onThirdChildDateOfBirthChange}
          required
          max={new Date().toLocaleDateString("fr-ca")}
        />
      </div>
      <SelectInput
        label={`Do both participants have Parental Responsibility for ${thirdChildFullName}?`}
        options={parentalResponsibilityOptions}
        onOptionChange={onThirdChildBothParentalResponsibilityChange}
        optionValue={thirdChildBothParentalResponsibility}
        required={true}
      />
      {thirdChildBothParentalResponsibility === "No" && (
        <TextInput
          label={`Who has parental responsibility for ${thirdChildFullName}?`}
          value={thirdChildParentalResponsibility}
          onChangeText={onThirdChildParentalResponsibilityChange}
          id="SecondChildParentalResponsibility"
          required={true}
        />
      )}
      <YesNoInput
        label={`Do ${state.clientOneFullName} and ${state.clientTwoFullName} have a Fourth child?`}
        name={"fourthChildCheck"}
        checkedTerm={fourthChildCheck}
        onOptionChange={onFourthChildCheckChange}
        required={true}
        noId="no-fourth-child"
        yesId="yes-fourth-child"
        noLabel="No"
        yesLabel="Yes"
      />
    </>
  );
};

export default ThirdChild;
