import { useState } from "react";
import useScrollTo from "../../../hooks/useScrollTo";
import TextInput from "../TextInput";
import YesNoInput from "../YesNoInput";
import TextArea from "../TextArea";

const DiscussionEight = ({ state, dispatch }) => {
  const [eighthAgenda, setEighthAgenda] = useState(state.eighthAgenda || "");
  const [eighthAgendaIssues, setEighthAgendaIssues] = useState(
    state.eighthAgendaIssues || ""
  );
  const [eighthAgendaOptions, setEighthAgendaOptions] = useState(
    state.eighthAgendaOptions || ""
  );
  const [eighthAgendaAgreements, setEighthAgendaAgreements] = useState(
    state.eighthAgendaAgreements
  );
  const [eighthAgendaAgreementsPoints, setEighthAgendaAgreementsPoints] =
    useState(state.eighthAgendaAgreementsPoints || "");
  const [eighthAgendaActionsPoints, setEighthAgendaActionsPoints] = useState(
    state.eighthAgendaActionsPoints || ""
  );

  useScrollTo(0, 0, "smooth");

  const onEighthAgendaChange = ({ target }) => {
    setEighthAgenda(target.value);
    dispatch({ type: "EIGHTH_AGENDA", payload: target.value });
  };
  const onEighthAgendaIssuesChange = ({ target }) => {
    setEighthAgendaIssues(target.value);
    dispatch({ type: "EIGHTH_AGENDA_ISSUES", payload: target.value });
  };
  const onEighthAgendaOptionsChange = ({ target }) => {
    setEighthAgendaOptions(target.value);
    dispatch({ type: "EIGHTH_AGENDA_OPTIONS", payload: target.value });
  };
  const onEighthAgendaAgreementsChange = ({ target }) => {
    setEighthAgendaAgreements(target.value);
    dispatch({ type: "EIGHTH_AGENDA_AGREEMENTS", payload: target.value });
  };
  const onEighthAgendaAgreementsPointsChange = ({ target }) => {
    setEighthAgendaAgreementsPoints(target.value);
    dispatch({
      type: "EIGHTH_AGENDA_AGREEMENTS_POINTS",
      payload: target.value,
    });
  };
  const onEighthAgendaActionsPointsChange = ({ target }) => {
    setEighthAgendaActionsPoints(target.value);
    dispatch({ type: "EIGHTH_AGENDA_ACTIONS_POINTS", payload: target.value });
  };

  return (
    <>
      <h2>Discussion - Point 8</h2>
      <TextInput
        label="eighth agenda point discussed."
        id="eighth_agenda"
        onChangeText={onEighthAgendaChange}
        value={eighthAgenda}
        required={true}
      />
      <TextArea
        rows={8}
        label="Issues identified"
        id="issues-identified"
        onTextChange={onEighthAgendaIssuesChange}
        value={eighthAgendaIssues}
        required={true}
        guide="Please add details and context about the issues discussed."
      />
      <TextArea
        rows={8}
        label="Options discussed"
        id="options-discussed"
        onTextChange={onEighthAgendaOptionsChange}
        value={eighthAgendaOptions}
        required={true}
        guide="List the proposals put forward by the participants."
      />
      <YesNoInput
        label="Any agreements reached?"
        name="agreements-reached"
        checkedTerm={eighthAgendaAgreements}
        onOptionChange={onEighthAgendaAgreementsChange}
        required={true}
        noId="no-agreements-reached"
        noLabel="No"
        yesId="yes-agreements-reached"
        yesLabel="Yes"
      />
      {eighthAgendaAgreements === "Yes" && (
        <TextArea
          rows={8}
          label="Please bullet point any agreements reached"
          name="agreement-points"
          id="agreement-points"
          value={eighthAgendaAgreementsPoints}
          onTextChange={onEighthAgendaAgreementsPointsChange}
          guide="Ensure there is enough detail to stablish a clear agreement."
          required={true}
        />
      )}
      <TextInput
        label="Action points for the clients"
        name="action-points"
        id="action-points"
        guide="Please specify any practical activities/actions that need to take place to allow the agreements to be reached."
        value={eighthAgendaActionsPoints}
        onChangeText={onEighthAgendaActionsPointsChange}
        required={true}
      />
    </>
  );
};

export default DiscussionEight;
