import { useState } from "react";
import YesNoInput from "../FormComponents/Components/YesNoInput";
import useScrollTo from "../hooks/useScrollTo";

const MAX_COUNT = 10;

const DocumentUpload = ({ state, dispatch }) => {
  const [additionalDocuments, setAdditionalDocuments] = useState(
    state.additionalDocuments
  );
  const [evidences, setEvidences] = useState(state.evidences || []);
  const [fileLimit, setFileLimit] = useState(false);

  useScrollTo(0, 0, "smooth");

  const onAdditionalDocumentsChange = ({ target }) => {
    setAdditionalDocuments(target.value);
    dispatch({ type: "ADDITIONAL_DOCUMENTS", payload: target.value });
  };

  const handleFileSelect = (files) => {
    const uploaded = [...evidences];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
        return false;
      }
      return false;
    });
    if (!limitExceeded) setEvidences(uploaded);
  };

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    dispatch({ type: "EVIDENCES", payload: chosenFiles });
    handleFileSelect(chosenFiles);
  };

  return (
    <>
      <h2>Document Upload</h2>
      <p>
        If there are not documents to be uploaded, please click the "Next"
        button at the bottom of this page to continue with this form.
      </p>
      <YesNoInput
        label="Are there any additional documents to upload?"
        checkedTerm={additionalDocuments}
        name="additionalDocuments"
        onOptionChange={onAdditionalDocumentsChange}
        noId="no-additionalDocuments"
        yesId="yes-additionalDocuments"
        noLabel="No"
        yesLabel="Yes"
      />
      {additionalDocuments === "Yes" && (
        <div className="form-control colum-direction">
          <h3>
            Please upload your evidence. You can upload up to 10 documents.
            <span className="color-red">*</span>
          </h3>
          <input
            onChange={handleFileEvent}
            type="file"
            disabled={fileLimit}
            name="evidences"
            id="evidences"
            multiple
            style={{
              display: "none",
            }}
          />
          <label htmlFor="evidences">
            <p
              style={{
                cursor: "pointer",
              }}
            >
              Upload Files
            </p>
          </label>
          <div className="uploaded-files-list">
            {evidences.map((file, idx) => (
              <p key={idx}>{file.name}</p>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default DocumentUpload;
