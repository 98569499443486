import React, { useState } from "react";
import YesNoInput from "../FormComponents/Components/YesNoInput";

import useScrollTo from "../hooks/useScrollTo";

import FirstChild from "../FormComponents/ChildComponents/FirstChild";
import SecondChild from "../FormComponents/ChildComponents/SecondChild";
import ThirdChild from "../FormComponents/ChildComponents/ThirdChild";
import FourthChild from "../FormComponents/ChildComponents/FourthChild";

const KeyFacts = ({ state, dispatch }) => {
  const [haveChildren, setHaveChildren] = useState(state.haveChildren);
  const [childIssue, setChildIssue] = useState(state.childIssue || "");
  const [finance, setFinance] = useState(state.finance || "");
  const [allIssues, setAllIssues] = useState(state.allIssues || "");

  useScrollTo(0, 0, "smooth");

  const onHavingChildrenChange = ({ target }) => {
    setHaveChildren(target.value);
    dispatch({ type: "HAVE_CHILDREN", payload: target.value });
  };
  const onChildIssuesChange = ({ target }) => {
    setChildIssue(target.value);
    dispatch({ type: "CHILD_ISSUE", payload: target.value });
  };
  const onFinanceChange = ({ target }) => {
    setFinance(target.value);
    dispatch({ type: "FINANCE", payload: target.value });
  };
  const onAllIssuesChange = ({ target }) => {
    setAllIssues(target.value);
    dispatch({ type: "ALL_ISSUE", payload: target.value });
  };

  return (
    <>
      <h2>Key Facts</h2>
      <YesNoInput
        label={`Do ${state.clientOneFullName} and ${state.clientTwoFullName} agreed to attend mediation together to try and agree arrangements to enable their child/children to spend time with both parents and agree a framework to allow them to parent her together in the future without conflict, and also to reach an agreement about financial arrangements following their decision to separate, which is fair to them both and reflects the time they have been together and have each made a contribution to the family finances?`}
        onOptionChange={onHavingChildrenChange}
        checkedTerm={haveChildren}
        required={true}
        yesId="yes-haveChildren"
        noId="no-haveChildren"
        name="haveChildren"
        noLabel="No"
        yesLabel="Yes"
      />
      {haveChildren === "Yes" && (
        <>
          <FirstChild state={state} dispatch={dispatch} />
          {haveChildren === "Yes" && state.secondChildCheck === "Yes" && (
            <>
              <SecondChild state={state} dispatch={dispatch} />
              {haveChildren === "Yes" &&
                state.secondChildCheck === "Yes" &&
                state.thirdChildCheck === "Yes" && (
                  <>
                    <ThirdChild state={state} dispatch={dispatch} />
                    {haveChildren === "Yes" &&
                      state.secondChildCheck === "Yes" &&
                      state.thirdChildCheck === "Yes" &&
                      state.fourthChildCheck === "Yes" && (
                        <FourthChild state={state} dispatch={dispatch} />
                      )}
                  </>
                )}
            </>
          )}
        </>
      )}
      {state.caseType === "Child Issues" && (
        <YesNoInput
          label={`Do ${state.clientOneFullName} and ${state.clientTwoFullName} agree to attend mediation to try and put together arrangements to enable their child/children to spend time with them both and to propose a framework to allow them to parent the child/children in the future without conflict?`}
          name="childIssues"
          checkedTerm={childIssue}
          onOptionChange={onChildIssuesChange}
          required={true}
          yesId="yes-child-issues"
          yesLabel="Yes"
          noId="no-child-issues"
          noLabel="No"
        />
      )}
      {state.caseType === "Finance & Property" && (
        <YesNoInput
          label={`Do ${state.clientOneFullName} and ${state.clientTwoFullName} wish to reach an agreement about financial arrangements following their decision to separate, which is fair to them both and reflects the time they have been together and have each made a contribution to the family finances?`}
          checkedTerm={finance}
          onOptionChange={onFinanceChange}
          required={true}
          name="finance"
          yesId="yes-finance"
          yesLabel="Yes"
          noId="no-finance"
          noLabel="No"
        />
      )}
      {state.caseType === "All issues" && (
        <YesNoInput
          label={`Do ${state.clientOneFullName} and ${state.clientTwoFullName} agreed to attend mediation together to try and agree arrangements to enable “Child 1 or children” to spend time with both parents and agree a framework to allow them to parent her together in the future without conflict, and also to reach an agreement about financial arrangements following their decision to separate, which is fair to them both and reflects the time they have been together and have each made a contribution to the family finances?`}
          checkedTerm={allIssues}
          onOptionChange={onAllIssuesChange}
          required={true}
          name="allIssues"
          yesId="yes-all-issues"
          yesLabel="Yes"
          noId="no-all-issues"
          noLabel="No"
        />
      )}
    </>
  );
};

export default KeyFacts;
