import { useLocation, useParams } from "react-router-dom";
import { onBackChange, onNextChange } from "../functions/navFunctions";

const FormComponent = ({
  logo,
  currentStepIndex,
  step,
  formStateMediationSession,
  isFirstStep,
  steps,
  isLastStep,
  next,
  goTo,
  back,
  setIsSubmitted,
}) => {
  const { id } = useParams();
  const { pathname } = useLocation();
  return (
    <>
      <div className="full-screen">
        <div className="form-screen">
          <div className="yellow-bar"></div>
          <header>
            <img src={logo} alt="Logo" />
          </header>
          <form
            className="form"
            onSubmit={(e) =>
              onNextChange(
                e,
                next,
                currentStepIndex,
                formStateMediationSession,
                goTo,
                steps,
                setIsSubmitted,
                id,
                pathname
              )
            }
          >
            <div className="steps">
              {currentStepIndex + 1} / {steps.length}
            </div>
            {step}
            <div>
              <div className="submit-btn submit">
                {!isFirstStep && (
                  <button
                    type="button"
                    className="back-btn"
                    onClick={(e) =>
                      onBackChange(
                        e,
                        back,
                        currentStepIndex,
                        formStateMediationSession,
                        goTo
                      )
                    }
                  >
                    Back
                  </button>
                )}
                <button
                  type="submit"
                  className="next-btn"
                  disabled={
                    (currentStepIndex === 0 &&
                      formStateMediationSession.caseType === "select") ||
                    (currentStepIndex === 0 &&
                      formStateMediationSession.specifyLocation === "select") ||
                    (currentStepIndex === 0 &&
                      formStateMediationSession.mediationSessionNumber ===
                        "select") ||
                    (currentStepIndex === 1 &&
                      formStateMediationSession.firstChildBothParentalResponsibility ===
                        "select" &&
                      formStateMediationSession.haveChildren === "Yes") ||
                    (currentStepIndex === 1 &&
                      formStateMediationSession.secondChildBothParentalResponsibility ===
                        "select" &&
                      formStateMediationSession.secondChildCheck === "Yes") ||
                    (currentStepIndex === 1 &&
                      formStateMediationSession.thirdChildBothParentalResponsibility ===
                        "select" &&
                      formStateMediationSession.thirdChildCheck === "Yes") ||
                    (currentStepIndex === 1 &&
                      formStateMediationSession.fourthChildBothParentalResponsibility ===
                        "select" &&
                      formStateMediationSession.fourthChildCheck === "Yes") ||
                    (currentStepIndex === 2 &&
                      formStateMediationSession.mediationTypes === "select") ||
                    (currentStepIndex === 12 &&
                      formStateMediationSession.isFurtherSession ===
                        "select") ||
                    (currentStepIndex === 12 &&
                      formStateMediationSession.mediationFinishedReason ===
                        "select" &&
                      formStateMediationSession.isFurtherSession === "No")
                  }
                  style={{
                    filter:
                      ((currentStepIndex === 0 &&
                        formStateMediationSession.caseType === "select") ||
                        (currentStepIndex === 0 &&
                          formStateMediationSession.specifyLocation ===
                            "select") ||
                        (currentStepIndex === 0 &&
                          formStateMediationSession.mediationSessionNumber ===
                            "select") ||
                        (currentStepIndex === 1 &&
                          formStateMediationSession.firstChildBothParentalResponsibility ===
                            "select" &&
                          formStateMediationSession.haveChildren === "Yes") ||
                        (currentStepIndex === 1 &&
                          formStateMediationSession.secondChildBothParentalResponsibility ===
                            "select" &&
                          formStateMediationSession.secondChildCheck ===
                            "Yes") ||
                        (currentStepIndex === 1 &&
                          formStateMediationSession.thirdChildBothParentalResponsibility ===
                            "select" &&
                          formStateMediationSession.thirdChildCheck ===
                            "Yes") ||
                        (currentStepIndex === 1 &&
                          formStateMediationSession.fourthChildBothParentalResponsibility ===
                            "select" &&
                          formStateMediationSession.fourthChildCheck ===
                            "Yes") ||
                        (currentStepIndex === 2 &&
                          formStateMediationSession.mediationTypes ===
                            "select") ||
                        (currentStepIndex === 12 &&
                          formStateMediationSession.isFurtherSession ===
                            "select") ||
                        (currentStepIndex === 12 &&
                          formStateMediationSession.mediationFinishedReason ===
                            "select" &&
                          formStateMediationSession.isFurtherSession ===
                            "No")) &&
                      "opacity(.4)",
                  }}
                >
                  {isLastStep ? "Finish" : "Next"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default FormComponent;
