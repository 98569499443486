import { useState } from "react";
import useScrollTo from "../../../hooks/useScrollTo";
import TextInput from "../TextInput";
import YesNoInput from "../YesNoInput";
import TextArea from "../TextArea";

const DiscussionFive = ({ state, dispatch }) => {
  const [fifthAgenda, setFifthAgenda] = useState(state.fifthAgenda || "");
  const [fifthAgendaIssues, setFifthAgendaIssues] = useState(
    state.fifthAgendaIssues || ""
  );
  const [fifthAgendaOptions, setFifthAgendaOptions] = useState(
    state.fifthAgendaOptions || ""
  );
  const [fifthAgendaAgreements, setFifthAgendaAgreements] = useState(
    state.fifthAgendaAgreements
  );
  const [fifthAgendaAgreementsPoints, setFifthAgendaAgreementsPoints] =
    useState(state.fifthAgendaAgreementsPoints || "");
  const [fifthAgendaActionsPoints, setFifthAgendaActionsPoints] = useState(
    state.fifthAgendaActionsPoints || ""
  );
  const [sixthAgendaCheck, setSixthAgendaCheck] = useState(
    state.sixthAgendaCheck
  );

  useScrollTo(0, 0, "smooth");

  const onFifthAgendaChange = ({ target }) => {
    setFifthAgenda(target.value);
    dispatch({ type: "FIFTH_AGENDA", payload: target.value });
  };
  const onFifthAgendaIssuesChange = ({ target }) => {
    setFifthAgendaIssues(target.value);
    dispatch({ type: "FIFTH_AGENDA_ISSUES", payload: target.value });
  };
  const onFifthAgendaOptionsChange = ({ target }) => {
    setFifthAgendaOptions(target.value);
    dispatch({ type: "FIFTH_AGENDA_OPTIONS", payload: target.value });
  };
  const onFifthAgendaAgreementsChange = ({ target }) => {
    setFifthAgendaAgreements(target.value);
    dispatch({ type: "FIFTH_AGENDA_AGREEMENTS", payload: target.value });
  };
  const onFifthAgendaAgreementsPointsChange = ({ target }) => {
    setFifthAgendaAgreementsPoints(target.value);
    dispatch({
      type: "FIFTH_AGENDA_AGREEMENTS_POINTS",
      payload: target.value,
    });
  };
  const onFifthAgendaActionsPointsChange = ({ target }) => {
    setFifthAgendaActionsPoints(target.value);
    dispatch({ type: "FIFTH_AGENDA_ACTIONS_POINTS", payload: target.value });
  };
  const onSixthAgendaCheck = ({ target }) => {
    setSixthAgendaCheck(target.value);
    dispatch({ type: "SIXTH_AGENDA_CHECK", payload: target.value });
  };
  return (
    <>
      <h2>Discussion - Point 5</h2>
      <TextInput
        label="fifth agenda point discussed."
        id="fifth_agenda"
        onChangeText={onFifthAgendaChange}
        value={fifthAgenda}
        required={true}
      />
      <TextArea
        rows={8}
        label="Issues identified"
        id="issues-identified"
        onTextChange={onFifthAgendaIssuesChange}
        value={fifthAgendaIssues}
        required={true}
        guide="Please add details and context about the issues discussed."
      />
      <TextArea
        rows={8}
        label="Options discussed"
        id="options-discussed"
        onTextChange={onFifthAgendaOptionsChange}
        value={fifthAgendaOptions}
        required={true}
        guide="List the proposals put forward by the participants."
      />
      <YesNoInput
        label="Any agreements reached?"
        name="agreements-reached"
        checkedTerm={fifthAgendaAgreements}
        onOptionChange={onFifthAgendaAgreementsChange}
        required={true}
        noId="no-agreements-reached"
        noLabel="No"
        yesId="yes-agreements-reached"
        yesLabel="Yes"
      />
      {fifthAgendaAgreements === "Yes" && (
        <TextArea
          rows={8}
          label="Please bullet point any agreements reached"
          name="agreement-points"
          id="agreement-points"
          value={fifthAgendaAgreementsPoints}
          onTextChange={onFifthAgendaAgreementsPointsChange}
          guide="Ensure there is enough detail to stablish a clear agreement."
          required={true}
        />
      )}
      <TextInput
        label="Action points for the clients"
        name="action-points"
        id="action-points"
        guide="Please specify any practical activities/actions that need to take place to allow the agreements to be reached."
        value={fifthAgendaActionsPoints}
        onChangeText={onFifthAgendaActionsPointsChange}
        required={true}
      />
      <YesNoInput
        label="Is there a Sixth agenda point?"
        checkedTerm={sixthAgendaCheck}
        onOptionChange={onSixthAgendaCheck}
        name="Sixth-agenda"
        required={true}
        noId="no-Sixth-agenda"
        noLabel="No"
        yesId="yes-Sixth-agenda"
        yesLabel="Yes"
      />
    </>
  );
};

export default DiscussionFive;
