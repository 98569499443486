import { useState } from "react";
import useScrollTo from "../../../hooks/useScrollTo";
import TextInput from "../TextInput";
import YesNoInput from "../YesNoInput";
import TextArea from "../TextArea";

const DiscussionThree = ({ state, dispatch }) => {
  const [thirdAgenda, setThirdAgenda] = useState(state.thirdAgenda || "");
  const [thirdAgendaIssues, setThirdAgendaIssues] = useState(
    state.thirdAgendaIssues || ""
  );
  const [thirdAgendaOptions, setThirdAgendaOptions] = useState(
    state.thirdAgendaOptions || ""
  );
  const [thirdAgendaAgreements, setThirdAgendaAgreements] = useState(
    state.thirdAgendaAgreements
  );
  const [thirdAgendaAgreementsPoints, setThirdAgendaAgreementsPoints] =
    useState(state.thirdAgendaAgreementsPoints || "");
  const [thirdAgendaActionsPoints, setThirdAgendaActionsPoints] = useState(
    state.thirdAgendaActionsPoints || ""
  );
  const [fourthAgendaCheck, setFourthAgendaCheck] = useState(
    state.fourthAgendaCheck
  );

  useScrollTo(0, 0, "smooth");

  const onThirdAgendaChange = ({ target }) => {
    setThirdAgenda(target.value);
    dispatch({ type: "THIRD_AGENDA", payload: target.value });
  };
  const onThirdAgendaIssuesChange = ({ target }) => {
    setThirdAgendaIssues(target.value);
    dispatch({ type: "THIRD_AGENDA_ISSUES", payload: target.value });
  };
  const onThirdAgendaOptionsChange = ({ target }) => {
    setThirdAgendaOptions(target.value);
    dispatch({ type: "THIRD_AGENDA_OPTIONS", payload: target.value });
  };
  const onThirdAgendaAgreementsChange = ({ target }) => {
    setThirdAgendaAgreements(target.value);
    dispatch({ type: "THIRD_AGENDA_AGREEMENTS", payload: target.value });
  };
  const onThirdAgendaAgreementsPointsChange = ({ target }) => {
    setThirdAgendaAgreementsPoints(target.value);
    dispatch({
      type: "THIRD_AGENDA_AGREEMENTS_POINTS",
      payload: target.value,
    });
  };
  const onThirdAgendaActionsPointsChange = ({ target }) => {
    setThirdAgendaActionsPoints(target.value);
    dispatch({ type: "THIRD_AGENDA_ACTIONS_POINTS", payload: target.value });
  };
  const onFourthAgendaCheck = ({ target }) => {
    setFourthAgendaCheck(target.value);
    dispatch({ type: "FOURTH_AGENDA_CHECK", payload: target.value });
  };
  return (
    <>
      <h2>Discussion - Point 3</h2>
      <TextInput
        label="Third agenda point discussed."
        id="third_agenda"
        onChangeText={onThirdAgendaChange}
        value={thirdAgenda}
        required={true}
      />
      <TextArea
        rows={8}
        label="Issues identified"
        id="issues-identified"
        onTextChange={onThirdAgendaIssuesChange}
        value={thirdAgendaIssues}
        required={true}
        guide="Please add details and context about the issues discussed."
      />
      <TextArea
        rows={8}
        label="Options discussed"
        id="options-discussed"
        onTextChange={onThirdAgendaOptionsChange}
        value={thirdAgendaOptions}
        required={true}
        guide="List the proposals put forward by the participants."
      />
      <YesNoInput
        label="Any agreements reached?"
        name="agreements-reached"
        checkedTerm={thirdAgendaAgreements}
        onOptionChange={onThirdAgendaAgreementsChange}
        required={true}
        noId="no-agreements-reached"
        noLabel="No"
        yesId="yes-agreements-reached"
        yesLabel="Yes"
      />
      {thirdAgendaAgreements === "Yes" && (
        <TextArea
          rows={8}
          label="Please bullet point any agreements reached"
          name="agreement-points"
          id="agreement-points"
          value={thirdAgendaAgreementsPoints}
          onTextChange={onThirdAgendaAgreementsPointsChange}
          guide="Ensure there is enough detail to stablish a clear agreement."
          required={true}
        />
      )}
      <TextInput
        label="Action points for the clients"
        name="action-points"
        id="action-points"
        guide="Please specify any practical activities/actions that need to take place to allow the agreements to be reached."
        value={thirdAgendaActionsPoints}
        onChangeText={onThirdAgendaActionsPointsChange}
        required={true}
      />
      <YesNoInput
        label="Is there a fourth agenda point?"
        checkedTerm={fourthAgendaCheck}
        onOptionChange={onFourthAgendaCheck}
        name="fourth-agenda"
        required={true}
        noId="no-fourth-agenda"
        noLabel="No"
        yesId="yes-fourth-agenda"
        yesLabel="Yes"
      />
    </>
  );
};

export default DiscussionThree;
