import React, { useState } from "react";
import TextInput from "../Components/TextInput";
import SelectInput from "../Components/SelectInput";
import YesNoInput from "../Components/YesNoInput";

const parentalResponsibilityOptions = ["Yes", "No"];

const SecondChild = ({ state, dispatch }) => {
  const [secondChildFullName, setSecondChildFullName] = useState(
    state.SecondChildFullName || ""
  );
  const [secondChildDateOfBirth, setSecondChildDateOfBirth] = useState(
    state.SecondChildDateOfBirth || ""
  );
  const [
    secondChildBothParentalResponsibility,
    setSecondChildBothParentalResponsibility,
  ] = useState(state.secondChildBothParentalResponsibility || "select");
  const [
    secondChildParentalResponsibility,
    setSecondChildParentalResponsibility,
  ] = useState(state.SecondChildParentalResponsibility || "");
  const [thirdChildCheck, setThirdChildCheck] = useState(state.thirdChildCheck);

  const onSecondChildFullNameChange = ({ target }) => {
    setSecondChildFullName(target.value);
    dispatch({ type: "SECOND_CHILD_FULL_NAME", payload: target.value });
  };
  const onSecondChildDateOfBirthChange = ({ target }) => {
    setSecondChildDateOfBirth(target.value);
    dispatch({ type: "SECOND_CHILD_DATE_OF_BIRTH", payload: target.value });
  };
  const onSecondChildBothParentalResponsibilityChange = ({ target }) => {
    setSecondChildBothParentalResponsibility(target.value);
    dispatch({
      type: "SECOND_CHILD_PARENTAL_RESPONSIBILITY_BOTH",
      payload: target.value,
    });
  };
  const onSecondChildParentalResponsibilityChange = ({ target }) => {
    setSecondChildParentalResponsibility(target.value);
    dispatch({
      type: "SECOND_CHILD_PARENTAL_RESPONSIBILITY",
      payload: target.value,
    });
  };
  const onThirdChildCheckChange = ({ target }) => {
    setThirdChildCheck(target.value);
    dispatch({ type: "THIRD_CHILD_CHECK", payload: target.value });
  };
  return (
    <>
      <TextInput
        label="What is the full name of the Second child?"
        required={true}
        value={secondChildFullName}
        onChangeText={onSecondChildFullNameChange}
        id="secondChildFullName"
      />
      <div className="form-control colum-direction">
        <label htmlFor="date-of-birth">
          What is {secondChildFullName}’s date of birth?
          <span className="color-red">*</span>
        </label>
        <input
          type="date"
          name="second-child-dob"
          id="second-child-date-of-birth"
          value={secondChildDateOfBirth}
          onChange={onSecondChildDateOfBirthChange}
          required
          max={new Date().toLocaleDateString("fr-ca")}
        />
      </div>
      <SelectInput
        label={`Do both participants have Parental Responsibility for ${secondChildFullName}?`}
        options={parentalResponsibilityOptions}
        onOptionChange={onSecondChildBothParentalResponsibilityChange}
        optionValue={secondChildBothParentalResponsibility}
        required={true}
      />
      {secondChildBothParentalResponsibility === "No" && (
        <TextInput
          label={`Who has parental responsibility for ${secondChildFullName}?`}
          value={secondChildParentalResponsibility}
          onChangeText={onSecondChildParentalResponsibilityChange}
          id="SecondChildParentalResponsibility"
          required={true}
        />
      )}
      <YesNoInput
        label={`Do ${state.clientOneFullName} and ${state.clientTwoFullName} have a third child?`}
        name={"thirdChildCheck"}
        checkedTerm={thirdChildCheck}
        onOptionChange={onThirdChildCheckChange}
        required={true}
        noId="no-third-child"
        yesId="yes-third-child"
        noLabel="No"
        yesLabel="Yes"
      />
    </>
  );
};

export default SecondChild;
