import { useState } from "react";
import useScrollTo from "../../../hooks/useScrollTo";
import TextInput from "../TextInput";
import YesNoInput from "../YesNoInput";
import TextArea from "../TextArea";

const DiscussionSeven = ({ state, dispatch }) => {
  const [seventhAgenda, setSeventhAgenda] = useState(state.seventhAgenda || "");
  const [seventhAgendaIssues, setSeventhAgendaIssues] = useState(
    state.seventhAgendaIssues || ""
  );
  const [seventhAgendaOptions, setSeventhAgendaOptions] = useState(
    state.seventhAgendaOptions || ""
  );
  const [seventhAgendaAgreements, setSeventhAgendaAgreements] = useState(
    state.seventhAgendaAgreements
  );
  const [seventhAgendaAgreementsPoints, setSeventhAgendaAgreementsPoints] =
    useState(state.seventhAgendaAgreementsPoints || "");
  const [seventhAgendaActionsPoints, setSeventhAgendaActionsPoints] = useState(
    state.seventhAgendaActionsPoints || ""
  );
  const [eighthAgendaCheck, setEighthAgendaCheck] = useState(
    state.eighthAgendaCheck
  );

  useScrollTo(0, 0, "smooth");

  const onSeventhAgendaChange = ({ target }) => {
    setSeventhAgenda(target.value);
    dispatch({ type: "SEVENTH_AGENDA", payload: target.value });
  };
  const onSeventhAgendaIssuesChange = ({ target }) => {
    setSeventhAgendaIssues(target.value);
    dispatch({ type: "SEVENTH_AGENDA_ISSUES", payload: target.value });
  };
  const onSeventhAgendaOptionsChange = ({ target }) => {
    setSeventhAgendaOptions(target.value);
    dispatch({ type: "SEVENTH_AGENDA_OPTIONS", payload: target.value });
  };
  const onSeventhAgendaAgreementsChange = ({ target }) => {
    setSeventhAgendaAgreements(target.value);
    dispatch({ type: "SEVENTH_AGENDA_AGREEMENTS", payload: target.value });
  };
  const onSeventhAgendaAgreementsPointsChange = ({ target }) => {
    setSeventhAgendaAgreementsPoints(target.value);
    dispatch({
      type: "SEVENTH_AGENDA_AGREEMENTS_POINTS",
      payload: target.value,
    });
  };
  const onSeventhAgendaActionsPointsChange = ({ target }) => {
    setSeventhAgendaActionsPoints(target.value);
    dispatch({ type: "SEVENTH_AGENDA_ACTIONS_POINTS", payload: target.value });
  };
  const onEighthAgendaCheck = ({ target }) => {
    setEighthAgendaCheck(target.value);
    dispatch({ type: "EIGHTH_AGENDA_CHECK", payload: target.value });
  };
  return (
    <>
      <h2>Discussion - Point 7</h2>
      <TextInput
        label="seventh agenda point discussed."
        id="seventh_agenda"
        onChangeText={onSeventhAgendaChange}
        value={seventhAgenda}
        required={true}
      />
      <TextArea
        rows={8}
        label="Issues identified"
        id="issues-identified"
        onTextChange={onSeventhAgendaIssuesChange}
        value={seventhAgendaIssues}
        required={true}
        guide="Please add details and context about the issues discussed."
      />
      <TextArea
        rows={8}
        label="Options discussed"
        id="options-discussed"
        onTextChange={onSeventhAgendaOptionsChange}
        value={seventhAgendaOptions}
        required={true}
        guide="List the proposals put forward by the participants."
      />
      <YesNoInput
        label="Any agreements reached?"
        name="agreements-reached"
        checkedTerm={seventhAgendaAgreements}
        onOptionChange={onSeventhAgendaAgreementsChange}
        required={true}
        noId="no-agreements-reached"
        noLabel="No"
        yesId="yes-agreements-reached"
        yesLabel="Yes"
      />
      {seventhAgendaAgreements === "Yes" && (
        <TextArea
          rows={8}
          label="Please bullet point any agreements reached"
          name="agreement-points"
          id="agreement-points"
          value={seventhAgendaAgreementsPoints}
          onTextChange={onSeventhAgendaAgreementsPointsChange}
          guide="Ensure there is enough detail to stablish a clear agreement."
          required={true}
        />
      )}
      <TextInput
        label="Action points for the clients"
        name="action-points"
        id="action-points"
        guide="Please specify any practical activities/actions that need to take place to allow the agreements to be reached."
        value={seventhAgendaActionsPoints}
        onChangeText={onSeventhAgendaActionsPointsChange}
        required={true}
      />
      <YesNoInput
        label="Is there a eighth agenda point?"
        checkedTerm={eighthAgendaCheck}
        onOptionChange={onEighthAgendaCheck}
        name="eighth-agenda"
        required={true}
        noId="no-eighth-agenda"
        noLabel="No"
        yesId="yes-eighth-agenda"
        yesLabel="Yes"
      />
    </>
  );
};

export default DiscussionSeven;
