import React, { useState } from "react";
import TextInput from "../Components/TextInput";
import SelectInput from "../Components/SelectInput";

const parentalResponsibilityOptions = ["Yes", "No"];

const FourthChild = ({ state, dispatch }) => {
  const [fourthChildFullName, setFourthChildFullName] = useState(
    state.fourthChildFullName || ""
  );
  const [fourthChildDateOfBirth, setFourthChildDateOfBirth] = useState(
    state.fourthChildDateOfBirth || ""
  );
  const [
    fourthChildBothParentalResponsibility,
    setFourthChildBothParentalResponsibility,
  ] = useState(state.fourthChildBothParentalResponsibility || "select");
  const [
    fourthChildParentalResponsibility,
    setFourthChildParentalResponsibility,
  ] = useState(state.fourthChildParentalResponsibility || "");

  const onFourthChildFullNameChange = ({ target }) => {
    setFourthChildFullName(target.value);
    dispatch({ type: "FOURTH_CHILD_FULL_NAME", payload: target.value });
  };
  const onFourthChildDateOfBirthChange = ({ target }) => {
    setFourthChildDateOfBirth(target.value);
    dispatch({ type: "FOURTH_CHILD_DATE_OF_BIRTH", payload: target.value });
  };
  const onFourthChildBothParentalResponsibilityChange = ({ target }) => {
    setFourthChildBothParentalResponsibility(target.value);
    dispatch({
      type: "FOURTH_CHILD_PARENTAL_RESPONSIBILITY_BOTH",
      payload: target.value,
    });
  };
  const onFourthChildParentalResponsibilityChange = ({ target }) => {
    setFourthChildParentalResponsibility(target.value);
    dispatch({
      type: "FOURTH_CHILD_PARENTAL_RESPONSIBILITY",
      payload: target.value,
    });
  };

  return (
    <>
      <TextInput
        label="What is the full name of the Fourth child?"
        required={true}
        value={fourthChildFullName}
        onChangeText={onFourthChildFullNameChange}
        id="fourthChildFullName"
      />
      <div className="form-control colum-direction">
        <label htmlFor="date-of-birth">
          What is {fourthChildFullName}’s date of birth?
          <span className="color-red">*</span>
        </label>
        <input
          type="date"
          name="fourth-child-dob"
          id="fourth-child-date-of-birth"
          value={fourthChildDateOfBirth}
          onChange={onFourthChildDateOfBirthChange}
          required
          max={new Date().toLocaleDateString("fr-ca")}
        />
      </div>
      <SelectInput
        label={`Do both participants have Parental Responsibility for ${fourthChildFullName}?`}
        options={parentalResponsibilityOptions}
        onOptionChange={onFourthChildBothParentalResponsibilityChange}
        optionValue={fourthChildBothParentalResponsibility}
        required={true}
      />
      {fourthChildBothParentalResponsibility === "No" && (
        <TextInput
          label={`Who has parental responsibility for ${fourthChildFullName}?`}
          value={fourthChildParentalResponsibility}
          onChangeText={onFourthChildParentalResponsibilityChange}
          id="SecondChildParentalResponsibility"
          required={true}
        />
      )}
    </>
  );
};

export default FourthChild;
