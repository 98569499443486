const TextInput = ({
  label,
  value,
  onChangeText,
  placeholder,
  id,
  required,
  pattern,
  guide,
  style,
  readOnly,
  anotherText,
  boldLabel,
}) => {
  return (
    <div className="form-control colum-direction">
      <label htmlFor={id}>
        {label}
        {boldLabel && <strong>{boldLabel}</strong>}
        {required && <span className="color-red">*</span>}
      </label>
      {guide && <div className="guide-label">{guide}</div>}
      <input
        type="text"
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={onChangeText}
        required={required || false}
        pattern={pattern}
        style={style}
        spellCheck="true"
        readOnly={readOnly}
      />
      <p className="guide-label">{anotherText}</p>
    </div>
  );
};

export default TextInput;
