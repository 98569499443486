import { useState } from "react";
import useScrollTo from "../hooks/useScrollTo";
import SelectInput from "../FormComponents/Components/SelectInput";
import YesNoInput from "../FormComponents/Components/YesNoInput";
import TextArea from "../FormComponents/Components/TextArea";

const RecordOfMattersDiscussed = ({ state, dispatch }) => {
  const mediationTypesOptions = ["Face to Face", "Shuttle", "Mix"];

  const [mediationTypes, setMediationTypes] = useState(
    state.mediationTypes || "select"
  );
  const [agreeToMediate, setAgreeToMediate] = useState(state.agreeToMediate);
  const [agendaPointsList, setAgendaPointsList] = useState(
    state.agendaPointsList || ""
  );

  useScrollTo(0, 0, "smooth");

  const onMediationTypesChange = ({ target }) => {
    setMediationTypes(target.value);
    dispatch({ type: "MEDIATION_TYPE", payload: target.value });
  };
  const onAgreeToMediationChange = ({ target }) => {
    setAgreeToMediate(target.value);
    dispatch({ type: "AGREE_TO_MEDIATE", payload: target.value });
  };
  const onAgendaPointsListChange = ({ target }) => {
    setAgendaPointsList(target.value);
    dispatch({ type: "AGENDA_POINTS_LIST", payload: target.value });
  };

  return (
    <>
      <h2>Record of Matters Discussed</h2>
      <SelectInput
        label="Type of mediation"
        required={true}
        options={mediationTypesOptions}
        onOptionChange={onMediationTypesChange}
        optionValue={mediationTypes}
      />
      <YesNoInput
        label={`Do ${state.clientOneFullName} and ${state.clientTwoFullName} agree to the Agreement to Mediate?`}
        checkedTerm={agreeToMediate}
        onOptionChange={onAgreeToMediationChange}
        required={true}
        name="agreeToMediate"
        yesId="yes-agree-to-mediate"
        yesLabel="Yes"
        noId="no-agree-to-mediate"
        noLabel="No"
      />
      <TextArea
        label="Please list the agreed agenda points"
        required={true}
        rows={5}
        value={agendaPointsList}
        onTextChange={onAgendaPointsListChange}
      />
    </>
  );
};

export default RecordOfMattersDiscussed;
