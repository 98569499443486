import React from "react";

const TextArea = ({
  value,
  onTextChange,
  required,
  label,
  id,
  guide,
  classes,
  cols,
  rows,
  defaultValue,
}) => {
  return (
    <div className="form-control colum-direction">
      <label htmlFor={id}>
        {label}
        {required && <span className="color-red">*</span>}
      </label>
      {guide && <p className="guide-label">{guide}</p>}
      <textarea
        id={id}
        className={classes}
        value={value}
        onChange={onTextChange}
        required={required}
        spellCheck="true"
        rows={rows}
        cols={cols}
        defaultValue={defaultValue}
      ></textarea>
    </div>
  );
};

export default TextArea;
